import {axios} from "../axios";
import * as yup from "yup";
import {ApiPage} from "../types/apiPage";
import {CurrencyKind} from "../dictionaries/currencies";
import {CustomerSubEntityDefaultFilter} from "./customerSubEntityFilter";
import {ApiReference} from "../types/apiReference";

export interface CustomerAccount {
    id: string;
    customer: string,
    name: string,
    currency: ApiReference<string>,
    accountNumber: string,
    kind: CurrencyKind,
    details: {
        fiat?: {
            bank?: BankId
            correspondent?: {
                account: string,
                bank: BankId
            },
            remark?: string
        }
    } | undefined
}

interface BankId {
    id: string,
    name: string,
    address: string,
}

export enum CustomerAccountBankIdentifierType {
    SWIFT = 'Swift',
    BIC = 'Bic',
    SEPA = 'Sepa'
}

export const accountsApi = {
    BASE_URL: "/accounts/v1/accounts",
    schemas: {
        common: yup.object()
            .shape({
                id: yup.string(),
                customer: yup.string()
                    .required('required'),
                name: yup.string()
                    .required('required'),
                accountNumber: yup.string()
                    .required('required'),
                currency: yup.object()
                    .required('required')
                    .shape({
                        id: yup.string()
                            .required('required')
                    }),
                details: yup.object()
                    .notRequired()
                    .default(undefined)
                    .shape({
                        fiat: yup.object()
                            .notRequired()
                            .default(undefined)
                            .shape({
                                bank: yup.object()
                                    .notRequired()
                                    .default(undefined)
                                    .shape({
                                        id: yup.string()
                                            .required('required'),
                                        name: yup.string()
                                            .required('required'),
                                        address: yup.string()
                                            .required('required'),
                                    }),
                                correspondent: yup.object()
                                    .notRequired()
                                    .default(undefined)
                                    .shape({
                                        account: yup.string()
                                            .required('required'),
                                        bank: yup.object()
                                            .notRequired()
                                            .default(undefined)
                                            .shape({
                                                id: yup.string()
                                                    .required('required'),
                                                name: yup.string()
                                                    .required('required'),
                                                address: yup.string()
                                                    .required('required'),
                                            })
                                            .required('required'),
                                    }),
                                remark: yup.string()
                            }),
                    })
            }),
    },

    list: async (filter?: CustomerSubEntityDefaultFilter, page: number = 0, size: number = 25) => {
        console.log("Listing with filter", filter)
        return (await axios.get<ApiPage<CustomerAccount>>(
            accountsApi.BASE_URL, {
                params: {
                    customer: filter?.customer?.id,
                    filter: filter?.filter,
                    page: page,
                    size: size
                }
            })).data
    },

    get: async (id: string) => (await axios.get<CustomerAccount>(`${accountsApi.BASE_URL}/${id}`)).data,

    post: async (account: CustomerAccount) => (await axios.post<CustomerAccount>(accountsApi.BASE_URL, account)).data,

    put: async (account: CustomerAccount) => (await axios.put<CustomerAccount>(`${accountsApi.BASE_URL}/${account.id}`, account)).data,

    delete: async (id: string) => (await axios.delete(`${accountsApi.BASE_URL}/${id}`)),

}
