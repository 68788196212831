import styled from "styled-components";
import {PRIMARY} from "../../style-constants";
import {useTranslation} from "react-i18next";
import {FC, ReactNode, useEffect, useState} from "react";
import {ApiPage, EMPTY_API_PAGE} from "../../../api/types/apiPage";
import {countriesApi, Country} from "../../../api/dictionaries/countries";
import {useForm} from "react-hook-form";
import {Form} from "../../forms/Form";
import {FormInputText} from "../../forms/FormInputs/FormInputText";
import {FormSaveButton} from "../../forms/FormSaveButton";
import {Button} from "../../components/Button";
import {IconPlus} from "../../icons/IconPlus";
import {IColumn, ITable, Table} from "../../components/Table";
import {EditButton} from "../../../app/components/EditButton";
import {Modal, ModalSize} from "../../components/Modal";
import {CountryForm} from "../../../app/modules/System/Dictionaries/CountriesPage/countryForm";
import {FieldValues} from "react-hook-form/dist/types";
import {axios} from "../../../api/axios";
import {AxiosError} from "axios";
import {DeleteButton} from "../../../app/components/DeleteButton";
import {NotificationManager} from "../../notifications/NotificationManager";

const FilterWrapper = styled.div`
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 1.5rem;
    align-items: end;
    
    svg {
        width: 0.7rem;
        margin-right: 0.5rem;
    }
`;


const DefaultFilterWrapper = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 1.5rem;
    align-items: end;
`;


interface TableApi<Entity extends ListEntity, Filter, ListEntity extends {id: string} = Entity, CreateRq = Entity> {
    list: (filter: Filter, page?: number, size?: number) => Promise<ApiPage<ListEntity>>;
    get: (id: string) => Promise<Entity>;
    post: (entity: CreateRq) => Promise<Entity>;
    put?: (entity: Entity) => Promise<Entity>;
    delete?: (id: string) => Promise<any>;
}

export interface ICreateForm<CreateRq> {
    submit: (_: CreateRq) => void,
    entity?: CreateRq
}

export interface IEditForm<Entity extends {id: string}> extends ICreateForm<Entity>{
}

export interface IFilterForm<Filter> {
    init?: Filter,
    submit: (_: Filter) => void
}

interface ITablePage<Entity extends ListEntity, Filter, ListEntity extends {id: string} = Entity, CreateRq = Entity, > {
    columns: IColumn<ListEntity>[],
    api: TableApi<Entity, Filter, ListEntity, CreateRq>
    pageSize?: number,
    translations?: string[],
    initCreation ?: any, // TODO: bind it to CreateRq
    editFormTitle?: ReactNode,
    editForm?: FC<IEditForm<Entity>>,
    createFormTitle?: ReactNode,
    createForm?: FC<ICreateForm<CreateRq>>,
    initFilter: Filter,
    filterForm?: FC<IFilterForm<Filter>>,
    formSize?: ModalSize
}

export interface DefaultFilter {
    filter: string | undefined
}

export const DefaultFilterInstance = {filter: undefined} as DefaultFilter

export const DefaultFilterForm = ({submit}: IFilterForm<DefaultFilter>) => {

    const {t} = useTranslation(["system"])

    const filterForm = useForm<DefaultFilter>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange'
    })

    return <Form onSubmit={filterForm.handleSubmit(submit)} overlay={filterForm.formState.isSubmitting} overlaySpinner={true}>
        <DefaultFilterWrapper>
            <FormInputText name="filter"
                           label={t("app:common.filter.title") as string}
                           form={filterForm}
            />


            <FormSaveButton state={filterForm.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ["app"],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            }}/>
        </DefaultFilterWrapper>
    </Form>

}

export const TablePage = <Entity extends ListEntity, Filter, ListEntity extends {id: string} = Entity, CreateRq = Entity, >({
    api,
    columns,
    pageSize = 10,
    translations = ["app"],
    initCreation,
    editFormTitle,
    editForm,
    createFormTitle = undefined,
    createForm = undefined,
    initFilter,
    filterForm,
    formSize,
}: ITablePage<Entity, Filter, ListEntity, CreateRq>) => {

    const {t} = useTranslation(translations)
    const [items, setItems] = useState<ApiPage<ListEntity>>(EMPTY_API_PAGE)
    const [overlay, setOverlay] = useState<boolean>(false)
    const [editFormOpened, setEditFormOpened] = useState<boolean>(false)
    const [createFormOpened, setCreateFormOpened] = useState<boolean>(false)
    const [createRq, setCreateRq] = useState<CreateRq | undefined>()
    const [editableEntity, setEditableEntity] = useState<Entity | undefined>()
    const [currentFilter, setCurrentFilter] = useState<Filter>(initFilter)

    const load = (filter: Filter, page: number = 0, size: number = pageSize) => {
        setOverlay(true)
        return api.list(
            filter,
            page,
            size
        )
    }

    const loadMore = (number: number) => {
        load(currentFilter, number).then( (data) => {
            setItems({
                items: [...items.items, ...data.items],
                page: data.page,
                size: data.size,
                hasMore: data.hasMore
            })
            setOverlay(false)
        }, error => {
            console.log(error)
            setOverlay(false)
        })
    }

    const loadNew = (value: Filter, page: number = 0, size: number = pageSize) => {
        setCurrentFilter(value)
        load(value, page, size).then( (data) => {
            setItems(data)
            setOverlay(false)
        }, error => {
            console.log(error)
            setOverlay(false)
        })
    }

    const refresh = () => {
        const loadedRecords = (items.page + 1) * items.size
        load(currentFilter, 0, loadedRecords).then( (data) => {
            setItems({
                items: data.items,
                page: items.page,
                size: items.size,
                hasMore: data.hasMore
            })
            setOverlay(false)
        }, error => {
            console.log(error)
            setOverlay(false)
        })
    }

    useEffect(() => {
        loadNew(initFilter)
    }, [])

    const createNew = () => {
        setEditableEntity(initCreation)
        setCreateFormOpened(true)
    }

    const editItem = (entity: ListEntity) => {
        setOverlay(true)
        api.get(entity.id).then((value) => {
            setEditableEntity(value)
            setOverlay(false)
            setEditFormOpened(true)
        }, error => {
            console.log(error)
            setOverlay(false)
        })
    }

    const processSavedEntity = (saved: Entity) => {
        /*const idx = items.items.findIndex(it => it.id === saved.id)
        if (idx >= 0) {
            items.items[idx] = saved
            console.log("Entity on ", idx, " changed to ", saved)
        } else {
            items.items = [saved, ...items.items]
        }
        */
        closeForm()
        refresh()
    }

    const processSaveError = (error: AxiosError<{messages: string[]}>) => {
        console.log("Error", error)
        if (error.response && error.response.data) {
            error.response.data.messages.forEach((m: string) => {
                NotificationManager.errorToast(m)
            })

        } else {
            console.log("Error during request", error)
        }
    }

    const saveCreateForm = (entity: CreateRq) => {
        api.post(entity).then(processSavedEntity, processSaveError)
    }

    const saveForm = (entity: Entity) => {
        if (api.put) {
            api.put(entity).then(processSavedEntity, processSaveError)
        }
    }

    const deleteItem = (entity: ListEntity) => {
        setOverlay(true)
        api.delete!!(entity.id).then(() => {
            /*const idx = items.items.findIndex(it => it.id === entity.id)
            if (idx != -1) {
                items.items.splice(idx, 1)
            }*/
            refresh()
            setOverlay(false)
        }, error => {
            setOverlay(false)
            console.log(error)
        })
    }

    const closeForm = () => {
        setEditFormOpened(false)
        setCreateFormOpened(false)
    }

    const EditForm = editForm;
    const CreateForm = createForm || editForm;
    const FilterForm = filterForm;

    return (<>
            {
                FilterForm && <FilterWrapper>
                    <FilterForm submit={loadNew} init={initFilter}/>

                    <Button color={"extra"} onClick={() => createNew()}>
                        <IconPlus />
                        {t('app:common.create')}
                    </Button>
                </FilterWrapper>
            }


            <Table columns={[
                    ...columns,
                    EditForm ? {id: "edit", width: 4, render: (el: ListEntity) => <EditButton onClick={() => editItem(el) } />} : null,
                    api.delete ? {id: "delete", width: 4, render: (el: ListEntity) => <DeleteButton color={"ghost"} confirmationText={t("app:tablePage.deleteConfirmation") as string} onClick={() => deleteItem(el) } />} : null,
                ].filter(it => !!it) as IColumn<ListEntity>[]}
                   items={ items }
                   onLoadMore={ loadMore }
                   overlay={overlay}
            />

            {
                EditForm && <Modal header={editFormTitle} opened={editFormOpened} onRequestClose={closeForm} size={formSize}>
                    <EditForm submit={ saveForm } entity={ editableEntity }/>
                </Modal>
            }
            {
                CreateForm && <Modal header={createFormTitle || editFormTitle} opened={createFormOpened} onRequestClose={closeForm} size={formSize}>
                    {/*@ts-ignore*/}
                    <CreateForm submit={ saveCreateForm } entity={ createRq }/>
                </Modal>
            }
        </>
    )

}
