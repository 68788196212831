import styled from "styled-components";
import React, { PropsWithChildren } from "react";
import { DANGER, FONT_EXTRA_DISABLED, FONT_MAIN } from "../../../ui-kit/style-constants";
import { IconTrash } from "../../../ui-kit/icons/IconTrash";

const DeleteWrapper = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;

    svg {
        height: 1rem;
        fill: ${ FONT_MAIN };
    }

    [disabled] {
        cursor: initial;
        fill: ${ FONT_EXTRA_DISABLED };
    }


    &:hover, &:focus {
        :not([disabled]) {
          svg {
            fill: ${ DANGER };
          }
        }
    }
`;

export const DeleteIconButton = (props: PropsWithChildren<React.ButtonHTMLAttributes<any>>) => {
    return <DeleteWrapper {...props}>
        <IconTrash />
    </DeleteWrapper>
}
