import {accountsApi, CustomerAccount, CustomerAccountBankIdentifierType} from "../../../../../api/customer/accountsApi";
import {IEditForm} from "../../../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Form, FormGroup, FormRow} from "../../../../../ui-kit/forms/Form";
import {FormInputText} from "../../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSelect, FormSelectAsync} from "../../../../../ui-kit/forms/FormInputs/FormSelect";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {currenciesApi, CurrencyKind} from "../../../../../api/dictionaries/currencies";
import {getEnumItems} from "../../../../../ui-kit/utils/enumUtils";
import {CustomerDeal, CustomerDealOtcSubtype, CustomerDealStatus, CustomerDealType, CustomerDealFeeType, dealsApi} from "../../../../../api/customer/contractsApi";
import {FormInputDate} from "../../../../../ui-kit/forms/FormInputs/FormInputDate";
import {Button} from "../../../../../ui-kit/components/Button";
import {DeleteButton} from "../../../../components/DeleteButton";
import {DeleteIconButton} from "../../../../components/DeleteIconButton";

export const CustomerDealEditForm = ({entity, submit}: IEditForm<CustomerDeal>) => {
    const {t} = useTranslation(['customers'])

    const form = useForm<CustomerDeal>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(dealsApi.schemas.common, {}, {})
    })

    const feesField = useFieldArray({
        control: form.control,
        name: "fees"
    })

    const loadCurrencies = async (filter: string, page: number) => {
        const result = await currenciesApi.list({filter: filter}, page, 10)
        return {
            items: result.items.map(el => ({
                id: el.id,
                description: `${el.code} - ${el.name}`,
                kind: el.kind
            })),
            hasMore: result.hasMore
        }
    }

    const dealType = form.watch().dealType

    return (
        <Form onSubmit={ form.handleSubmit(submit) } overlay={ form.formState.isSubmitting } overlaySpinner={ true }>
            <FormInputText name="id" form={form} hidden={true} />
            <FormInputText name="customer" form={form} hidden={true} />

            <FormRow sizes={["1fr", "1fr", "1fr", "1fr"]}>
                <FormSelect name="dealType"
                            items={getEnumItems(CustomerDealType)}
                            label={t("customers:deals.dealType")}
                            form={form}
                />

                <FormInputText name="dealNumber"
                               label={t("customers:deals.dealNumber")}
                               form={form}
                />

                <FormInputDate name="dealDate"
                               label={t("customers:deals.dealDate")}
                               form={form}
                />
                <FormSelect name="status"
                            items={getEnumItems(CustomerDealStatus)}
                            label={t("customers:deals.status")}
                            form={form}
                />
            </FormRow>
            <FormInputText name="remark"
                           label={t("customers:deals.remark")}
                           form={form}
            />

            { dealType === CustomerDealType.OTC &&
                <FormGroup title={t("customers:deals.otc.title")}>
                    <FormSelect name="details.otc.subtype"
                                items={getEnumItems(CustomerDealOtcSubtype)}
                                label={t("customers:deals.otc.subtype")}
                                form={form}
                    />

                    <FormRow sizes={["1fr", "1fr"]}>
                        <FormSelectAsync name={"details.otc.currencySell"}
                                         label={t("customers:deals.otc.currencySell") }
                                         loadItems={loadCurrencies}
                                         form={form}
                        />

                        <FormSelectAsync name={"details.otc.currencyBuy"}
                                         label={t("customers:deals.otc.currencyBuy") }
                                         loadItems={loadCurrencies}
                                         form={form}
                        />
                    </FormRow>
                </FormGroup>
            }

            <FormGroup title={t("customers:deals.fees.title")}>
                {

                    feesField.fields.map((field, index) => {
                        return <FormGroup title={`${t("customers:deals.fees.titleSingle")} ${index+1}`}>
                            <FormRow sizes={["1fr", "1fr", "2fr", "min-content"]}>
                                <FormSelect name={`fees.${index}.type`}
                                            label={t("customers:deals.fees.type")}
                                            form={form}
                                            items={ getEnumItems(CustomerDealFeeType)} />
                                <FormInputText name={`fees.${index}.amount` as const}
                                               label={t("customers:deals.fees.amount")}
                                               form={form} />
                                <FormInputText name={`fees.${index}.remark` as const}
                                               label={t("customers:deals.fees.remark")}
                                               form={form} />
                                <DeleteIconButton onClick={() => feesField.remove(index) } style={{marginTop: '1.5rem'}} ></DeleteIconButton>
                            </FormRow>
                        </FormGroup>
                    })
                }
                <Button onClick={() => feesField.append({type: CustomerDealFeeType.BASIC, amount: 0, remark: ''}) } >{t("customers:deals.fees.add")}</Button>
            </FormGroup>

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={ {
                translationNamespaces: ['app'],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            } }/>
        </Form>


    )
}
