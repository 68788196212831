import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import {Customer} from "../../../../../api/customer/customersApi";

export const CustomerTransactions = () => {
    const {t} = useTranslation(["customers"])
    const {customer} = useOutletContext<{ customer: Customer }>()

    return (
        <>
           {/* {customer && <TablePage api={transactionsApi}
                                    filterForm={FilterForm}
                                    initFilter={{customer}}
                                    columns={[
                                        {id: "accountNumber", name: t("customers:accounts.number") as string}
                                    ]}
                                    translations={["customers"]}
                                    pageSize={25}
                                    initCreation={ {customer: customer.id} }
                                    editFormTitle={t('customers:accounts.titleSingle')}
                                    editForm={CustomerAccountEditForm}
                                    formSize="xxxl"

            />}*/}
        </>
    )
}
