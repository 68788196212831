import React  from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import styled, { css } from "styled-components";
import { isMobile } from "../utils";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { RecoilRoot, useRecoilValue } from "recoil";
import { Authorize } from "./Authorize";
import { FingerPrintLoader } from "./bounders/FingerPrintLoader";
import { CurrentUserSetter } from "./bounders/CurrentUserSetter";
import { AxiosBounder } from "./bounders/AxiosBounder";
import { sessionDataState } from "./globalState/sessionDataState";
import { BG_MAIN } from "../ui-kit/style-constants";
import { NotificationContainer } from "../ui-kit/notifications/NotificationContainer";
import {currentUserState} from "./globalState/currentUserState";
import {AuthorizeOverlay} from "./components/AuthorizeOverlay";
import {ADMIN_ROLES} from "../api/authApi/authBaseTypes";
import {NoAccessPage} from "./components/NoAccessPage";
import { Customers } from "./modules/CustomersPage";
import { CryptoVerificationPage } from "./modules/CryptoVerificationPage";
import { Audit } from "./modules/Audit";
import {CountriesPage} from "./modules/System/Dictionaries/CountriesPage";
import {System} from "./modules/System";
import {Dictionaries} from "./modules/System/Dictionaries";
import {CurrenciesPage} from "./modules/System/Dictionaries/CurrenciesPage";
import {BusinessActivitiesPage} from "./modules/System/Dictionaries/BusinessActivitiesPage";
import {UsersPage} from "./modules/UsersPage";
import {CustomerPage} from "./modules/CustomersPage/CustomerPage";
import {CustomerAccounts} from "./modules/CustomersPage/CustomerPage/CustomerAccounts";
import {CustomerContracts} from "./modules/CustomersPage/CustomerPage/CustomerContracts";
import {CustomerDeals} from "./modules/CustomersPage/CustomerPage/CustomerDeals";
import {CustomerTransactions} from "./modules/CustomersPage/CustomerPage/CustomerTransactions";
import {UserPage} from "./modules/UsersPage/UserPage";
import {CustomerMainInfo} from "./modules/CustomersPage/CustomerPage/CustomerMainInfo";
import {CustomerUsers} from "./modules/CustomersPage/CustomerPage/CustomerUsers";

const PageWrapper = styled.div`
    min-height: 100vh;
    min-width: 320px;
    display: grid;
    grid-template-rows: min-content auto min-content;

    background: ${ BG_MAIN };

    html, body {
        box-sizing: border-box;
        ${ isMobile ?
            css`
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            ` : ''
        }
    }
`;

const InnerPage = styled.div`
    justify-self: stretch;
    align-self: stretch;
    background: ${ BG_MAIN };
`;

const AppOrAuth = () => {
    const sessionData = useRecoilValue(sessionDataState);
    const currentUser = useRecoilValue(currentUserState);

    return (
        !sessionData ? <Authorize /> :
        !currentUser ? <AuthorizeOverlay /> :
        currentUser.roles.findIndex((it) => { return ADMIN_ROLES.indexOf(it) != -1 }) == -1 ? <NoAccessPage /> :
        <PageWrapper>
            <Header/>
            <InnerPage>
                <Routes>
                    <Route path='/customers/*' element={ <Customers /> } />
                    <Route path='/customers/:customerId' element={ <CustomerPage /> }>
                        <Route path={'main'} element={<CustomerMainInfo />} />
                        <Route path={'users'} element={<CustomerUsers />} />
                        <Route path={'accounts/*'} element={<CustomerAccounts />} />
                        <Route path={'contracts/*'} element={<CustomerContracts />} />
                        <Route path={'deals/*'} element={<CustomerDeals />} />
                        <Route path={'transactions/*'} element={<CustomerTransactions />} />
                        <Route path='' element={<Navigate to='main' />} />
                    </Route>
                    <Route path='/users/*' element={<UsersPage />} />
                    <Route path='/users/:userId' element={ <UserPage /> } />
                    <Route path='/crypto-verification/*' element={ <CryptoVerificationPage /> } />
                    <Route path='/audit/*' element={ <Audit /> } />
                    <Route path='/system/*' element={ <System /> }>
                        <Route path={'dictionaries/*'} element={ <Dictionaries /> }>
                            <Route index path={'countries'} element={<CountriesPage />}/>
                            <Route index path={'currencies'} element={<CurrenciesPage />}/>
                            <Route index path={'business-activities'} element={<BusinessActivitiesPage />}/>
                        </Route>
                    </Route>
                    <Route path='/' element={<Navigate to='/customers' />} />
                    <Route path='*' element={<Navigate to={window.location.search ? `/${window.location.search}` : '/' } />} />
                </Routes>
            </InnerPage>
            <Footer/>
        </PageWrapper>
    )

}

export const App = () => {
    return (
        <div className="App">
            <RecoilRoot>
                <FingerPrintLoader />
                <AxiosBounder />
                <CurrentUserSetter />
                <BrowserRouter>
                    <AppOrAuth />
                </BrowserRouter>
                <NotificationContainer position={"top"} />
            </RecoilRoot>
        </div>
    )
}
