import {FieldValues} from "react-hook-form/dist/types/fields";
import {IFormInput} from "../IFormInput";
import {useTranslation} from "react-i18next";
import {Controller, FieldError} from "react-hook-form";
import {resolveError} from "../utils";
import {FormInputHint, FormInputLabel, FormInputWrapper} from "../base";
import React from "react";
import {SelectAsync, SelectAsyncProps} from "../../../inputs/Select";
import {SelectAsyncItem} from "../../../inputs/Select/SelectAsyncItem";

export const FormSelectAsync = <T extends string | number, Item extends SelectAsyncItem, TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    {
        name,
        hint,
        label,
        form,
        placeholder,
        disabled = false,
        error = false,
        disableErrorHint = false,
        loadItems,
        multiple,
        hidden = false,
        onChange,
        onBlur,
        ...props
    }: IFormInput<TFieldValues, TContext> & Omit<SelectAsyncProps<Item>, "value">) => {

    const {t} = useTranslation(["app"])

    const errors = form.formState.errors as { [key in keyof TFieldValues]: FieldError }
    const touchedFields = form.formState.touchedFields as { [key in keyof TFieldValues]: boolean }

    const errorHint = touchedFields[name] && resolveError(t, errors[name])

    const isError = touchedFields[name] && !!errors[name]?.type

    return (
        <FormInputWrapper hidden={hidden}>
            {
                !!label && <FormInputLabel htmlFor={ name }>{ label }</FormInputLabel>
            }
            <Controller control={ form.control as any }
                        name={ name }
                        render={ ({field}) => {
                            return <SelectAsync loadItems={loadItems}
                                               value={ field.value }
                                               onChange={ (val: any) => {
                                                   //@ts-ignore
                                                   field.onChange({target: {value: val}})
                                                   onChange && onChange(val)
                                               } }
                                               onBlur={ val => {
                                                   field.onBlur()
                                                   onBlur && onBlur(val)
                                               }}
                                               disabled={ disabled || form.formState.isSubmitting }
                                               id={ name }
                                               name={ name }
                                               error={ isError }
                                               placeholder={ placeholder ? t(placeholder) as string : '' }
                            />
                        } }
            />

            {
                !disableErrorHint && (hint || errorHint) && <FormInputHint error={ errorHint }>{ errorHint || hint }</FormInputHint>
            }
        </FormInputWrapper>

    )
}
