import { axios } from "../axios";
import * as yup from "yup";
import { ILLEGAL_COUNTRIES } from "../../app/constants";
import { ChallengeKind, CurrentUser, SystemRole, Verification } from "./authBaseTypes";
import { ApiPage } from "../types/apiPage";

enum EntityType {
    User = 'User'
}

enum EventType {
    Authentication = 'Authentication',
    Verification = 'Verification'
}

export interface AuditEvent {
    id: string,
    brand: string,
    createdAt: Date,
    entityType: EntityType,
    entityId: string,
    eventType: EventType,
    authorId: string,
    description: string,
}

export const auditEventApi = {

    list: async (params: { entityId ?: string, pageNumber?: number, pageSize?: number}) => {
        return (await axios.get<ApiPage<AuditEvent>>(`/auth/v1/audit-events`, { params })).data
    }
}
