import styled from "styled-components";
import { FC, useEffect, useState } from "react";
import { NotificationProps } from "./NotificationProps";
import { NotificationManager } from "./NotificationManager";
import { ToastNotification } from "./ToastNotification";

export type Position = 'top' | 'bottom';

const Wrapper = styled.div<{ position: Position }>`
    width: 100%;
    position: fixed;
    left: 0;
    ${ ({position}) => position }: 0;
    pointer-events: none;
    z-index: ${ 1 << 15 - 1 };
`;

const ToastNotifications = styled.div`
    position: fixed;
    top: 1rem;
    right: 1rem;
`;

export const NotificationContainer: FC<{ position: Position }> = ({position}) => {

    const [notifications, setNotifications] = useState<NotificationProps[]>([])

    useEffect(() => {
        const handleChange = (newValue: NotificationProps[]) => {
            setNotifications([...newValue])
        }

        NotificationManager.addEventListener(handleChange)
        return () => {
            NotificationManager.removeEventListener(handleChange)
        }
    }, [setNotifications])


    const lines = notifications.filter(it => it.type === 'line')
    const toasts = notifications.filter(it => it.type === 'toast')

    return (
        <Wrapper position={ position }>
            {
                lines.map(it => {
                    return "Line Notifications Has Not Ready Yet!"
                })
            }
            {
                toasts && <ToastNotifications>
                    {
                        toasts.map(it =>
                            <ToastNotification notification={ it }
                                               onHide={() => NotificationManager.remove(it) }
                                               key={it.id} />
                        )
                    }
                </ToastNotifications>
            }
        </Wrapper>
    )
}
