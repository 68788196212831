import { InputHTMLAttributes, PropsWithChildren, useState } from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { InputLabel } from "./base/InputLabel";
import { InputWrapper } from "./base/InputWrapper";
import { InputHint } from "./base/InputHint";
import { InputComponent } from "./base/InputComponent";
import { resolveError } from "../forms/FormInputs/utils";
import { IInput } from "./IInput";
import styled from "styled-components";
import { IconEye } from "../icons/IconEye";
import { IconEyeSlash } from "../icons/IconEyeSlash";

const InputComponentWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const ShowPasswordButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem;
    height: initial;
    font-size: 0.8rem;
    background: transparent;
    cursor: pointer;
    
    svg {
        width: 1rem;
        height: 1rem;
    }
    
`;

const InputComponentWithPadding = styled(InputComponent)`
    padding-right: 2.75rem;
`;

export const InputPassword = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>({
    name,
    hint,
    label,
    form,
    disableErrorHint = false,
    error = false,
    ...props
}: PropsWithChildren<IInput<TFieldValues, TContext> & Omit<InputHTMLAttributes<HTMLInputElement>, "name" | "form" | "type">>) => {

    const {t} = useTranslation(["app"])

    const [visible, setVisible] = useState(false)

    const errors = form.formState.errors as { [key in keyof TFieldValues]: FieldError }
    const touchedFields = form.formState.touchedFields as { [key in keyof TFieldValues]: boolean }

    const errorHint = touchedFields[name] && resolveError(t, errors[name])

    return (
        <InputWrapper>
            {
                !!label && <InputLabel htmlFor={ name }>{ label }</InputLabel>
            }
            <InputComponentWrapper>
                <InputComponentWithPadding {
                                    ...form.register(
                                        name,
                                        {
                                            onChange: props.onChange,
                                            onBlur: props.onBlur,

                                        }
                                    )
                                }
                                { ...props }
                                type={ visible ? 'text': 'password'}
                                error={ touchedFields[name] && !!errors[name]?.type }
                                disabled={ props.disabled || form.formState.isSubmitting }
                                id={ name }
                                name={ name }/>

                <ShowPasswordButton onClick={ () => setVisible(!visible)} type="button" tabIndex={-1}>
                    { visible ? <IconEye /> : <IconEyeSlash /> }
                </ShowPasswordButton>

            </InputComponentWrapper>

            {
                !disableErrorHint && (hint || errorHint) && <InputHint error={ errorHint }>{ errorHint || hint }</InputHint>
            }

        </InputWrapper>
    )
}
