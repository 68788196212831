import {FC} from "react";
import {CreateUserRq, usersApi} from "../../../api/authApi/usersApi";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Form, FormRow} from "../../../ui-kit/forms/Form";
import {FormInputText} from "../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSaveButton} from "../../../ui-kit/forms/FormSaveButton";
import {FormCheckbox} from "../../../ui-kit/forms/FormInputs/FormCheckbox";
import {FormInputDate} from "../../../ui-kit/forms/FormInputs/FormInputDate";
import {FormSelect, FormSelectAsync} from "../../../ui-kit/forms/FormInputs/FormSelect";
import {getEnumItems} from "../../../ui-kit/utils/enumUtils";
import {SystemRole} from "../../../api/authApi/authBaseTypes";
import {countriesApi} from "../../../api/dictionaries/countries";
import {FormMultiSelect} from "../../../ui-kit/forms/FormInputs/FormSelect/FormMultiSelect";
import {BRANDS_ITEMS} from "../../../api/types/brands";
import {yupResolver} from "@hookform/resolvers/yup";

export const CreateUserForm: FC<{ submit: (_: CreateUserRq) => void, entity?: CreateUserRq }> = ({
    submit,
    entity = undefined
}) => {

    const {t} = useTranslation(['users'])

    const form = useForm<CreateUserRq>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(usersApi.schemas.create, {}, {})
    })

    return (

        <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
            <FormRow sizes={["1fr", "2fr", "7rem"]}>
                <FormSelect name="brand"
                            label={t('users:users.form.brand')}
                            form={form}
                            items={BRANDS_ITEMS}
                />
                <FormInputText name="email"
                               label={t('users:users.form.email')}
                               form={form}
                />
                <FormCheckbox name="locked"
                              label={t('users:users.form.locked')}
                              form={form}
                />
            </FormRow>
            <FormRow sizes={["1fr", "1fr", "1fr"]}>
                <FormInputText name="name"
                               label={t('users:users.form.name')}
                               form={form}
                />
                <FormInputText name="middleName"
                               label={t('users:users.form.middleName')}
                               form={form}
                />
                <FormInputText name="lastName"
                               label={t('users:users.form.lastName')}
                               form={form}
                />
            </FormRow>
            <FormRow sizes={["1fr", "1fr"]}>

                <FormInputDate name="birthDate"
                               label={t('users:users.form.birthDate')}
                               form={form}
                />
                <FormSelectAsync name="residenceCountry"
                                 label={t('users:users.form.residenceCountry')}
                                 form={form}
                                 loadItems={countriesApi.loadCountries(10)}
                />
            </FormRow>

            <FormMultiSelect name="roles"
                             form={form}
                             label={t('users:users.form.roles')}
                             multiple={true}
                             items={getEnumItems(SystemRole)}
            />

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ['app'],
                save: 'app:common.forms.save',
                saving: 'app:common.forms.saving'
            }}/>

        </Form>
    )

}
