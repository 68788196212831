import styled from "styled-components";
import { MainMenu } from "../MainMenu";
import { UserInfo } from "../UserInfo";
import { ReactComponent as LegexLogo } from "../../../assets/legex/logo.svg";
import { sessionDataState } from "../../globalState/sessionDataState";
import { authApi } from "../../../api/authApi";
import { useRecoilState } from "recoil";
import { BG_MAIN_ALT, FONT_EXTRA_DISABLED, FONT_MAIN, PRIMARY_EXTRA } from "../../../ui-kit/style-constants";
import { LanguageSelector } from "../LanguageSelector";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../../utils/hocs/withSuspense";

const HeaderWrapper = styled.div`
    background: ${ BG_MAIN_ALT };
    display: grid;
    
    grid-template-columns: min-content auto min-content min-content min-content;
    align-items: center;
    padding-right: 1.5rem;
`;

const LogoWrapper = styled.div`
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0.5rem;
    svg {
        margin-top: -0.05rem;
        fill: ${FONT_MAIN};
    }
`;

const ExitButton = styled.button`
    color: ${FONT_EXTRA_DISABLED};
    font-size: 0.9rem;
    margin-right: 1rem;
    margin-left: 1rem;
    background: transparent;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 0;
    cursor: pointer;
    
    &:hover, &:focus {
        color: ${FONT_MAIN};
        background: ${PRIMARY_EXTRA};
    }
`;

export const Header = withSuspense(() => {

    const [sessionData, setSessionData] = useRecoilState(sessionDataState)

    const {t} = useTranslation(['app'])

    const exit = () => {
        if (sessionData?.sessionId)
        authApi.sessions.delete(sessionData?.sessionId).then(response => setSessionData(undefined) )
    }

    return (
        <HeaderWrapper>
            <LogoWrapper>
                <LegexLogo />
            </LogoWrapper>
            <MainMenu />
            <UserInfo />
            <ExitButton onClick={exit} disabled={!sessionData?.sessionId}>{t('app:common.exit')}</ExitButton>
            <LanguageSelector />
        </HeaderWrapper>
    )

}, null )
