import React from 'react';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { App } from "./app/App";
import { createRoot } from "react-dom/client";

createRoot(document.getElementById('root')!!)
    .render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
