import {FieldValues} from "react-hook-form/dist/types/fields";
import {InputHTMLAttributes, PropsWithChildren} from "react";
import {Checkbox, IInput} from "../../../inputs";
import {FormInputHint, FormInputLabel, FormInputWrapper} from "../base";
import {Controller, FieldError} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {resolveError} from "../utils";

export const FormCheckbox = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    {
        name,
        hint,
        label,
        form,
        disabled,
        placeholder,
        error = false,
        hidden,
        disableErrorHint = false,
    }: PropsWithChildren<IInput<TFieldValues, TContext> & Omit<Omit<InputHTMLAttributes<HTMLInputElement>, "name">, "form">>
) => {

    const {t} = useTranslation(["app"])

    const errors = form.formState.errors as { [key in keyof TFieldValues]: FieldError }
    const touchedFields = form.formState.touchedFields as { [key in keyof TFieldValues]: boolean }

    const errorHint = touchedFields[name] && resolveError(t, errors[name])

    const isError = touchedFields[name] && !!errors[name]?.type || error || false

    return (
        // <Checkbox {...form.register(name)} label={label} />
        <FormInputWrapper hidden={hidden || false}>
            {
                !!label && <FormInputLabel htmlFor={name}>{label}</FormInputLabel>
            }
            <Controller control={form.control as any}
                        name={name}
                        render={({field}) => {
                            return <Checkbox checked={field.value}
                                             onChange={e => field.onChange(e)}
                                             onBlur={field.onBlur}
                                             disabled={disabled || form.formState.isSubmitting}
                                             id={name}
                                             name={name}
                                             label={undefined}
                                             placeholder={placeholder ? t(placeholder) as string : ''}
                            />
                        }}
            />
            {
                !disableErrorHint && (hint || errorHint) &&
                <FormInputHint error={errorHint}>{errorHint || hint}</FormInputHint>
            }

        </FormInputWrapper>
    )
}
