import { axios } from "../axios";
import * as yup from "yup";
import { ILLEGAL_COUNTRIES } from "../../app/constants";
import { CurrentUser } from "./authBaseTypes";

export interface UpdateSelfRq {
    name: string,
    middleName: string,
    lastName: string,
}

export const userApi = {
    schemas: {
        common: yup.object({
            name: yup.string()
                .required('required'),
            middleName: yup.string(),
            lastName: yup.string(),
            residenceCountry: yup.string()
                .required('required')
                .test('illegal-country', 'failed', (value, {path, createError}) => {
                    return value && ILLEGAL_COUNTRIES.includes(value) ? createError({path, message: 'countryProhibited'}) : true
                }),
        }),
    },

    get: async () => {
        return (await axios.get<CurrentUser>("/auth/v1/user")).data
    },

    update: async (user: UpdateSelfRq) => {
        return axios.post(
            'auth/v1/user',
            user
        )
    },
    changePhoto: async (file: File) => {
        const formData = new FormData()
        formData.append("photo", file)

        return axios.post(
            "/auth/v1/user/photo",
            formData
        )
    }
}
