import { FC } from "react";
import { FormState } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../../utils/hocs/withSuspense";
import { Button } from "../../components/Button";

export interface IFormSaveButton {
    state: FormState<any>,
    visible ?: 'always' | 'dirty',
    position ?: 'left' | 'right',
    enabledOnlyIfDirty ?: boolean,
    buttonLabels ?: {
        translationNamespaces: string[]
        save: string,
        saving: string
    }
}

export const FormSaveButton: FC<IFormSaveButton> = withSuspense(({
    state,
    position = 'left',
    visible = 'always',
    enabledOnlyIfDirty = false,
    buttonLabels = {
        translationNamespaces: ['app'],
        save: 'app:forms.save',
        saving: 'app:forms.saving'
    }
}) => {
    const {t} = useTranslation(buttonLabels.translationNamespaces)

    return (
        <Button type="submit"
                disabled={ !state.isValid || state.isSubmitting || state.isValidating || enabledOnlyIfDirty && !state.isDirty }
                style={ {
                    visibility: visible === 'always' || state.isDirty ? 'visible' : 'hidden',
                    marginLeft: position === 'left' ? 'initial' : 'auto'
                } }>
            { t(state.isSubmitting ? buttonLabels.saving : buttonLabels.save)}
        </Button>
    )
})

