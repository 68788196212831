import styled from "styled-components";
import React, { MouseEvent, MouseEventHandler, PropsWithChildren, useState } from "react";
import { DANGER, FONT_EXTRA_DISABLED, FONT_MAIN } from "../../../ui-kit/style-constants";
import { IconTrash } from "../../../ui-kit/icons/IconTrash";
import { Modal } from "../../../ui-kit/components/Modal";
import { Button, IButtonProps } from "../../../ui-kit/components/Button";
import { useTranslation } from "react-i18next";

const DeleteWrapper = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;

    svg {
        height: 1rem;
        fill: ${ FONT_MAIN };
    }

    [disabled] {
        cursor: initial;
        fill: ${ FONT_EXTRA_DISABLED };
    }


    &:hover, &:focus {
        :not([disabled]) {
            svg {
                fill: ${ DANGER };
            }
        }
    }
`;

const Buttons = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: end;
    grid-gap: 1rem;
`;

const ModalContent = styled.div`
    display: grid;
`;


export interface DeleteButtonProps extends IButtonProps {
    confirmation ?: boolean;
    confirmationText ?: string;
}

export const DeleteButton = ({
    confirmation = true,
    confirmationText,
    onClick,
     ...props
}: PropsWithChildren<DeleteButtonProps>) => {

    const {t} = useTranslation(["app"])
    const [confirmationOpened, setConfirmationOpened] = useState<boolean>(false)

    const deleteAction = (e: MouseEvent<any>) => {
        if (confirmation) {
            setConfirmationOpened(true)
        } else {
            perform(e)
        }
    }

    const closeConfirmation = () => {
        setConfirmationOpened(false)
    }

    const perform = (e: MouseEvent<any>) => {
        if (onClick) onClick(e)
        closeConfirmation()
    }

    return <>
        <Button color={ props.color || 'danger'} { ...props } onClick={ deleteAction }>
            { props.children || <IconTrash /> }
        </Button>
        <Modal header="Confirmation"
               opened={ confirmationOpened }
               size={ "xs" }
               onRequestClose={ closeConfirmation }
               container={document.body}
               shouldCloseOnOverlayClick={true}>

            <ModalContent>
                { confirmationText || t("app:common.defaultConfirmation") }
                <Buttons>
                    <Button color="danger" onClick={ perform }>{ t("app:common.confirm") }</Button>
                    <Button color="secondary" onClick={ closeConfirmation }>{ t("app:common.cancel") }</Button>
                </Buttons>
            </ModalContent>
        </Modal>
    </>
}
