import { ReactComponent as LegexLogo } from "../../../assets/legex/logo.svg";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../ui-kit/components/Spinner";
import { BG_MAIN, FONT_MAIN } from "../../../ui-kit/style-constants";

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-gap: 1rem;
    background: ${BG_MAIN};
    
    >* {
        align-self: center;
        justify-self: center;
    }
`;

const Disclaimer = styled.div`
    font-weight: 200;
    font-size: 2rem;
    margin-bottom: 0.5rem;
`;

const LogoWrapper = styled.div`
    border-radius: 4rem;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
    
    svg {
       fill: ${FONT_MAIN}; 
    }
`;

export const AuthorizeOverlay = () => {
    const {t} = useTranslation(["app"])
    return (
        <Wrapper>
            <LogoWrapper>
                <LegexLogo />
            </LogoWrapper>
            <Disclaimer>{t("app:common.authorizing")}...</Disclaimer>
            <Spinner />
        </Wrapper>
    )
}
