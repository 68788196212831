import styled from "styled-components";
import { transparentize } from "polished";
import React, {FormHTMLAttributes, PropsWithChildren, ReactNode} from "react";
import {BG_MAIN, BG_MAIN_ALTER, BG_MAIN_ALTEREST, FONT_MAIN_ALT, FONT_MAIN_DISABLED, INPUT_BG_DISABLED, PRIMARY, PRIMARY_DISABLED} from "../../style-constants";
import { Spinner } from "../../components/Spinner";

const FormWrapper = styled.form`

    >div {
        display: grid;
        grid-gap: 1.5rem;
    }
    
    position: relative;
`;

const Overlay = styled.div`
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${transparentize(0.65, BG_MAIN)};
    z-index: 999999;
`;

export const FormRow = styled.div<{sizes: string[]}>`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    align-items: start;
    ${({sizes}) =>
        sizes && `grid-template-columns: ${sizes.join(' ')};`
    }
    
`;

const FormGroupWrapper = styled.fieldset`
    padding: 1rem;
    border: 1px solid ${BG_MAIN_ALTEREST};
    border-radius: 0.5rem;
    display: grid;  
    grid-gap: 1.5rem;
    align-items: start;
`;
const FormGroupTitle = styled.legend`
    font-size: 0.8rem;
    color: ${FONT_MAIN_DISABLED};
`;

export const FormGroup = ({title, children}: {title: string, children: ReactNode[] | ReactNode }) => {
    return <FormGroupWrapper>
        <FormGroupTitle>{title}</FormGroupTitle>
        {children}
    </FormGroupWrapper>
}

export type IForm = FormHTMLAttributes<any> & {
    overlay ?: boolean,
    overlaySpinner ?: boolean
}

export const Form = ({children, overlay = false, overlaySpinner = false, ...props}: PropsWithChildren<IForm>) => {
    return (
        <FormWrapper {...props}>
            <div>
                {children}
            </div>
            { overlay && <Overlay> {overlaySpinner && <Spinner />}</Overlay> }
        </FormWrapper>
    )
}
