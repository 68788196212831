import styled from "styled-components";
import { PropsWithChildren, ReactNode, useState } from "react";
import { ApiPage } from "../../../api/types/apiPage";
import { BG_MAIN, BG_MAIN_ALT, FONT_EXTRA_ALT, FONT_EXTRA_DISABLED, PRIMARY } from "../../style-constants";
import { Overlay } from "../Overlay";
import { useTranslation } from "react-i18next";
import { Pagination } from "../Pagination";
import { Button } from "../Button";

const TableWrapper = styled.div`
    position: relative;
`;

const TableElement = styled.table`
    width: 100%;
`;

const Headers = styled.thead`
    background: ${ PRIMARY };
    color: ${ FONT_EXTRA_ALT };
    font-weight: 600;
`;

const Header = styled.th`
    padding: 1rem;
    white-space: nowrap;
    text-align: left;
`;

const Row = styled.tr<{ odd: boolean }>`
    background: ${ ({odd}) => odd ? BG_MAIN : BG_MAIN_ALT };
`;

const Cell = styled.td`
    padding: 1rem;
    font-size: 0.9rem;
    vertical-align: middle;
`;

const CellContent = styled.div`
    display: inline-grid;
    align-items: center;
    justify-items: left;
    grid-auto-flow: column;
`;

const NoData = styled.div`
    display: grid;
    min-height: 5rem;
    justify-items: center;
    align-items: center;
    color: ${ FONT_EXTRA_DISABLED }
`;

const LoadMoreWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;
`;

export interface IColumn<T extends { id: string }> {
    id: keyof T | string,
    name?: ReactNode,
    width?: number,
    render?: (record: T) => ReactNode
}

export interface ITable<T extends { id: string }> {
    columns: IColumn<T>[],
    //pageChange?: (number: number, size: number) => void,
    onLoadMore?: (number: number) => void,
    items?: T[] | ApiPage<T>,
    refreshAt?: any[],
    overlay?: boolean
}

export interface ITableActions {
    refresh: () => void,
    showOverlay: () => void,
    hideOverlay: () => void
}

export const Table = <T extends { id: string }>(props: PropsWithChildren<ITable<T>>) => {

    const DEFAULT_PAGE_SIZE = 10;
    const DEFAULT_PAGE_SIZES = [5, 10, 25, 50];

    const {t} = useTranslation(['app'])
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

    console.log("Redraw table")

    /*const actions: ITableActions = {
        refresh: () => {
            if (props.loadItems) {
                pageChange((records as ApiPage<T>)?.number || 0, (records as ApiPage<T>)?.size || DEFAULT_PAGE_SIZE)
            }
        },
        showOverlay: () => {
            setOverlay(true)
        },
        hideOverlay: () => {
            setOverlay(false)
        }
    }*/
    /*const pageChange = (number: number, size?: number) => {
        setPageSize(size || DEFAULT_PAGE_SIZE)
        if (props.pageChange) {
            props.pageChange(number, size | DEFAULT_PAGE_SIZE)
        }
        /!*if (props.loadItems) {
            actions.showOverlay()
            props.loadItems(number, size).then(response => {
                setRecords(response)
                setPageSize(response.size)
                actions.hideOverlay()
            })
        }*!/
    }*/

    // useEffect(() => {
    //     console.log("effect, items, loadItems - first load", props.items, props.loadItems);
    //     //pageChange(0, DEFAULT_PAGE_SIZE)
    // }, [props.items, props.loadItems])

    const items = props.items && 'hasMore' in props.items ? props.items.items : props.items
    return (
        <TableWrapper>
            <TableElement cellPadding={ 0 } cellSpacing={ 0 }>
                <Headers>
                    <tr>
                        {
                            props.columns.map(col =>
                                <Header key={ col.id as string }
                                        style={ {width: col.width ? `${ col.width }rem` : 'auto'} }>
                                    { col.name }
                                </Header>
                            )
                        }
                    </tr>
                </Headers>
                <tbody>
                {
                    items && items.map((record, index) =>
                        <Row key={ record.id } odd={ index % 2 == 0 }>
                            {
                                props.columns.map(col =>
                                    <Cell key={ col.id as string }>
                                        <CellContent>{ (col.render ? col.render(record) : record[col.id as keyof T]) as string }</CellContent>
                                    </Cell>)
                            }
                        </Row>
                    )
                }
                </tbody>
            </TableElement>
            {
                (!items || items.length === 0) && <NoData>{ t('app:common.noData') }</NoData>
            }
            {
                (props.items as ApiPage<T>)?.hasMore && props.onLoadMore && <LoadMoreWrapper>
                    <Button color="secondary" onClick={ () => props.onLoadMore!!((props.items as ApiPage<T>).page + 1) }>{ t('app:common.loadMore') }</Button>
                </LoadMoreWrapper>
            }
            {/*{
                props.loadItems ?
                    <Pagination hasNext={ (records as ApiPage<T>)?.hasMore || false }
                                hasPrev={ ((records as ApiPage<T>)?.number || 0) > 0 }
                                pageNumber={ (records as ApiPage<T>)?.number || 0 }
                                sizes={{
                                    initial: (records as ApiPage<T>)?.size || pageSize || DEFAULT_PAGE_SIZE,
                                    available: DEFAULT_PAGE_SIZES
                                }}
                                refreshAt={ [...(props.refreshAt || [])] }
                                onPageChange={ pageChange } />
                    : null
            }*/}

            <Overlay show={ props.overlay || false } spinner={ true }/>
        </TableWrapper>
    )
}
