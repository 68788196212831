import { ReactComponent as SpinnerSvg } from "../../../assets/legex/spinner.svg";
import { FC } from "react";
import styled from "styled-components";
import { BG_MAIN, FONT_MAIN } from "../../style-constants";

type SpinnerSize = 'xss' | 'xs' | 'm' | 'xl' | 'xxl';
type SpinnerColor = 'light' | 'dark' | 'current';

const SIZES = {
    xss: '1rem',
    xs: '1.5rem',
    m: '2rem',
    xl: '3rem',
    xxl: '5rem'
}

const Wrapper = styled.div<{size: SpinnerSize, color: SpinnerColor}>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        width: ${({size}) => SIZES[size]};
        height: ${({size}) => SIZES[size]};
        animation: spin 2s linear infinite;
        fill: ${({color}) => color === 'light' ? FONT_MAIN : color === 'current' ? 'currentColor' : BG_MAIN};
        opacity: 0.5;
    }
    
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform:rotate(360deg);
        }
    }
    
`;

export const Spinner: FC<{
    size?: SpinnerSize,
    color?: SpinnerColor
}> = ({size = 'm', color = 'light'}) => {
    return (
        <Wrapper size={size} color={color}>
            <SpinnerSvg />
        </Wrapper>
    )
}

