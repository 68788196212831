import {useTranslation} from "react-i18next";
import {ModuleMenu} from "../../../components/ModuleMenu";
import {Outlet} from "react-router-dom";

export const Dictionaries = () => {

    const {t} = useTranslation(["system"])

    return (
        <>
            <ModuleMenu secondLevel elements={ [
                { tag: t("system:menu.dictionaries.countries"), link: "countries" },
                { tag: t("system:menu.dictionaries.currencies"), link: "currencies" },
                { tag: t("system:menu.dictionaries.businessActivities"), link: "business-activities" },
            ]} />
            <Outlet />
        </>
    )
}
