import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {PRIMARY} from "../../../ui-kit/style-constants";
import {DefaultFilter, DefaultFilterForm, DefaultFilterInstance, TablePage} from "../../../ui-kit/pages/TablePage";
import {EditButton} from "../../components/EditButton";
import {CustomerList, customersApi} from "../../../api/customer/customersApi";
import {CustomerCreateForm} from "./CustomerCreateForm";
import {BRANDS_ITEMS} from "../../../api/types/brands";

const Wrapper = styled.div`
    border-top: 0.2rem solid ${PRIMARY};
    padding: 2rem;
`;

export const Customers = () => {

    const {t} = useTranslation(["customers"])
    const navigate = useNavigate();

    return (
        <Wrapper>
            <TablePage api={customersApi}
                       columns={[
                           {id: "id", name: t('customers:customer.table.columns.id')},
                           {id: "brand", name: t('customers:customer.table.columns.brand'), render: (cust: CustomerList) => BRANDS_ITEMS.find(it => it.value == cust.brand)?.label || cust.brand},
                           {id: "name", name: t('customers:customer.table.columns.name')},
                           {id: "registrationNumber", name: t('customers:customer.table.columns.registrationNumber')},
                           {id: "residenceCountry", name: t('customers:customer.table.columns.residenceCountry'), render: (it: CustomerList) => it.residenceCountry?.description},
                           {id: "edit", width: 4, render: (el: CustomerList) => <EditButton onClick={() => `${navigate(el.id)}/main` } />},
                       ]}
                       filterForm={DefaultFilterForm}
                       initFilter={DefaultFilterInstance}
                       createForm={CustomerCreateForm}
                       createFormTitle={t('customers:customer.form.createTitle')}
            />

        </Wrapper>
    )
}
