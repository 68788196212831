import { axios } from "../axios";
import { ApiPage } from "../types/apiPage";

export interface Session {
    id: string,
    createdAt: string,
    currentSession: boolean,
    lastIp?: string,
    deviceType?: string,
    operatingSystem?: string,
    application: string,
    country?: string,
    city?: string
}

export const sessionsApi = {
    list: async (pageNumber: number = 0, pageSize: number = 10) => {
        return (await axios.get<ApiPage<Session>>(`/auth/v1/sessions`, {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize
            }
        })).data
    },
    delete: async (id: string) => {
        return (await axios.delete(`/auth/v1/sessions/${ id }`))
    }
}
