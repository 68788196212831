import {useForm} from "react-hook-form";
import {FormInputText} from "../../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {Form} from "../../../../../ui-kit/forms/Form";
import {useTranslation} from "react-i18next";
import {FC} from "react";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import {businessActivitiesApi, BusinessActivity} from "../../../../../api/dictionaries/businessActivities";
import {FormSelectAsync} from "../../../../../ui-kit/forms/FormInputs/FormSelect";
import {countriesApi} from "../../../../../api/dictionaries/countries";

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    align-items: start;
`

export const BusinessActivitiesForm: FC<{submit: (_: BusinessActivity) => void, entity?: BusinessActivity}> = ({submit, entity = undefined}) => {

    const {t} = useTranslation(['system'])

    const form = useForm<BusinessActivity>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(businessActivitiesApi.schemas.common, {}, {})
    })

    return (
        <Form onSubmit={ form.handleSubmit(submit) } overlay={ form.formState.isSubmitting } overlaySpinner={ true }>
            <FormInputText name="id" form={form} hidden={true} />
                <FormInputText name="code"
                               label={t("system:dictionaries.businessActivities.code") as string}
                               form={form}
                />

            <FormInputText name="name"
                           label={t("system:dictionaries.businessActivities.name") as string}
                           form={form}
            />

            <FormSelectAsync name={"country"}
                             label={t("system:dictionaries.businessActivities.country") as string}
                             loadItems={countriesApi.loadCountries(10)}
                             form={form}/>

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={ {
                translationNamespaces: ['app'],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            } }/>
        </Form>


    )

}
