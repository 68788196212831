import {TablePage} from "../../../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {accountsApi} from "../../../../../api/customer/accountsApi";
import {CustomerAccountEditForm} from "./CustomerAccountEditForm";
import {useOutletContext} from "react-router-dom";
import {Customer} from "../../../../../api/customer/customersApi";
import {CustomerSubEntityFilterForm} from "../../CusotmerSubentityFilter";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";


export const CustomerAccounts = () => {
    const {t} = useTranslation(["customers"])
    const {customer} = useOutletContext<{ customer: Customer }>()

    return (
        <DefaultPageWrapper line={false}>
            {customer && <TablePage api={accountsApi}
                                    filterForm={CustomerSubEntityFilterForm}
                                    initFilter={{customer}}
                                    columns={[
                                        {id: "name", name: t("customers:accounts.name")},
                                        {id: "accountNumber", name: t("customers:accounts.number")}
                                    ]}
                                    translations={["customers"]}
                                    pageSize={25}
                                    initCreation={ {customer: customer.id} }
                                    editFormTitle={t('customers:accounts.titleSingle')}
                                    editForm={CustomerAccountEditForm}
                                    formSize="xxxl"

            />}
        </DefaultPageWrapper>
    )
}
