import * as yup from "yup";
import {axios} from "../axios";
import {ApiPage} from "../types/apiPage";
import {DefaultFilter} from "../../ui-kit/pages/TablePage";

export interface Country {
    id: string,
    code: string,
    iso2: string,
    iso3: string,
    name: string,
    prohibited: boolean
}


export const countriesApi = {
    BASE_URL: "/dictionaries/v1/countries",
    schemas: {
        common: yup.object({
            id: yup.string(),
            code: yup.string()
                .required('required')
                .matches(/^[0-9]{3}$/, {message: 'codeShouldBeThreeDigit'}),
            name: yup.string()
                .required('required'),
            iso2: yup.string()
                .required('required')
                .matches(/^[A-Z]{2}$/, {message: 'codeShouldBeTwoUpperCaseLetters'}),
            iso3: yup.string()
                .required('required')
                .matches(/^[A-Z]{3}$/, {message: 'codeShouldBeTwoUpperCaseLetters'}),
            prohibited: yup.boolean()
        }),
    },

    loadCountries: (pageSize: number) => async (filter: string, page: number) => {
        const result = await countriesApi.list({filter: filter}, page, pageSize)
        return {
            items: result.items.map(el => ({
                id: el.id,
                description: `${el.iso3} - ${el.name}`
            })),
            hasMore: result.hasMore
        }
    },

    list: async (filter?: DefaultFilter, page: number = 0, size: number = 25) => {
        return (await axios.get<ApiPage<Country>>(countriesApi.BASE_URL, {
            params: {
                filter: filter?.filter,
                page: page,
                size: size
            }
        })).data
    },

    get: async (id: string) => {
        return (await axios.get<Country>(`${countriesApi.BASE_URL}/${id}`)).data
    },

    post: async (country: Country) => {
        return (await axios.post<Country>(countriesApi.BASE_URL, country)).data
    },

    put: async (country: Country) => {
        return (await axios.put<Country>(`${countriesApi.BASE_URL}/${country.id}`, country)).data
    },

    delete: async (id: string) => {
        return (await axios.delete(`${countriesApi.BASE_URL}/${id}`))
    },


}
