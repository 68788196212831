import styled from "styled-components";
import {FC, Fragment, ReactNode} from "react";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../../utils/hocs/withSuspense";
import { Link, useLocation } from "react-router-dom";
import {PRIMARY, FONT_EXTRA_ALT, BG_MAIN} from "../../../ui-kit/style-constants";

type IModuleMenuAlign = 'center' | 'left' | 'right'

export type IModuleMenu = {
    elements: {
        tag: ReactNode;
        link: string;
        neverSelected?: boolean,
    }[],
    secondLevel?: boolean,
    align?: IModuleMenuAlign,
}

const Menu = styled.div<{secondLevel: boolean, align: IModuleMenuAlign}>`
    background: ${PRIMARY};
    display: flex;
    justify-content: ${({align}) => align};
    align-items: center;
    padding: 0.5rem 1rem;
    
    ${({secondLevel}) => secondLevel && `
        border-top: 1px solid ${ BG_MAIN };
    `}}
`;

const MenuElement = styled(Link)<{ selected?: boolean, secondLevel: boolean }>`
    padding: 0.15rem 0;
    margin: 0 1rem 0.1rem;
    font-size: ${({secondLevel}) => secondLevel ? `0.75rem` : `0.9rem`};
    color: ${ FONT_EXTRA_ALT };
    border-bottom: ${ ({selected}) => selected ? `1px solid ${ FONT_EXTRA_ALT }` : '1px solid transparent' };
`

const MenuElementSplitter = styled.div`
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.5rem;
    margin: 0 0.5rem 0.1rem;
    background-color: ${ FONT_EXTRA_ALT };
`;

const ModuleMenuComponent: FC<IModuleMenu> = ({
    elements,
    secondLevel = false,
    align = 'center'
}) => {

    const {t} = useTranslation(['app', 'profile'])
    const location = useLocation();

    return (
        <Menu secondLevel={secondLevel} align={align}>
            {
                elements.map((it, index) => {
                    return (
                        <Fragment key={it.link}>
                            { index === 0 ? '' : <MenuElementSplitter/> }
                            <MenuElement secondLevel={secondLevel} to={ it.link } selected={ !it.neverSelected && location.pathname.startsWith(it.link) }>{ it.tag }</MenuElement>
                        </Fragment>
                    )
                })
            }
        </Menu>
    )

}

export const ModuleMenu = withSuspense(ModuleMenuComponent, null);
