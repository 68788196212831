import {axios} from "../axios";
import {ApiPage} from "../types/apiPage";
import * as yup from "yup";
import {ApiReference} from "../types/apiReference";

export type CryptoVerificationDirection = "Withdrawal" | "Deposit";

export interface CryptoCurrency {
    currency: string,
    issuer?: string,
    name: string,
    symbol: string,
    tokenId: number
}

export interface CryptoVerificationRq {
    direction: CryptoVerificationDirection,
    addressHash: string,
    transactionHash: string,
    currency: string,
    tokenId?: number,
    token?: string,
    customer?: ApiReference<string>,
}

export interface CryptoVerification {
    id: string,
    createdAt: string,
    createdBy: CryptoVerificationAuthor,
    direction: CryptoVerificationDirection,
    addressHash: string,
    transactionHash: string,
    currency: string,
    tokenId?: number,
    token?: string,
    result: CryptoVerificationResult,
    customer?: { id: string, name: string },
}

export interface CryptoVerificationAuthor {
    id: string,
    name: string,
    middleName?: string,
    lastName?: string,
}

export interface CryptoVerificationResult {
    address: string,
    txn?: string,
    time: string,
    updatedAt: string,
    amount: number,
    fiat: number,
    fiatCode: string,
    direction: string,
    riskScore: number,
    signals: CryptoVerificationResultSignals,
    status: string,
    riskyVolume: number,
    riskyVolumeFiat: number,
    riskLevel: string
}

export interface CryptoVerificationResultSignals {
    atm: number,
    darkMarket: number,
    darkService: number,
    exchangeLicensed: number,
    exchangeUnlicensed: number,
    p2pExchangeLicensed: number,
    p2pExchangeUnlicensed: number,
    childExploitation: number,
    exchangeFraudulent: number,
    enforcementAction: number,
    gambling: number,
    illegalService: number,
    marketplace: number,
    miner: number,
    mixer: number,
    payment: number,
    ransom: number,
    scam: number,
    stolenCoins: number,
    wallet: number,
    terrorismFinancing: number,
    liquidityPools: number,
    sanctions: number,
    seizedAssets: number,
    other: number,
}

export interface CryptoVerificationFilter {
    customer ?: ApiReference<string>,
    filter ?: string,
    timeFrom?: string,
    timeTo?: string,
    verifiedAtFrom?: string,
    verifiedAtTo?: string
}


export const cryptoVerificationApi = {
    BASE_URL: "/auth/v1/crypto-verification",
    schemas: {
        common: yup.object()
            .shape({
                id: yup.string(),
                addressHash: yup.string()
                    .required('required')
            }),
        create: yup.object()
            .shape({
                addressHash: yup.string()
                    .required('required')
            })
    },

    currencies: async () => {
        return (await axios.get<CryptoCurrency[]>(`/auth/v1/crypto-verification/crypto-currencies`)).data
    },

    list: async (filter?: CryptoVerificationFilter, page: number = 0, size: number = 25) => {
        console.log("Listing with ", filter, page, size)
        return (await axios.get<ApiPage<CryptoVerification>>(cryptoVerificationApi.BASE_URL, {
            params: {
                customerId: filter?.customer?.id,
                filter: filter?.filter,
                timeFrom: filter?.timeFrom,
                timeTo: filter?.timeTo,
                verifiedAtFrom: filter?.verifiedAtFrom,
                verifiedAtTo: filter?.verifiedAtTo,
                page: page,
                size: size
            }
        })).data
    },

    get: async (id: string) => {
        return (await axios.get<CryptoVerification>(`${cryptoVerificationApi.BASE_URL}/${id}`)).data
    },

    post: async (createRq: CryptoVerificationRq) => {
        return (await axios.post<CryptoVerification>(cryptoVerificationApi.BASE_URL, createRq)).data
    },

    print: async (id: string) => {
        (await axios.get(`${cryptoVerificationApi.BASE_URL}/${id}/print?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, {
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = href;

            console.log("H", response.headers.get)
            // @ts-ignore
            const disposition = response.headers.get("Content-Disposition") || ""
            console.log("Disp", disposition)
            const filename = disposition.indexOf("filename=") > -1 ? disposition.substring(disposition.indexOf("filename=") + 9) : "ver.pdf"

            link.setAttribute('download', filename)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }))
    }

}
