import i18n from 'i18next';
import { initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const Languages: {
    [key: string]: {
        short: string,
        full: string
    }
} = {
    en: {
        short: "En",
        full: "English",
    },
    ru: {
        short: "Ру",
        full: "Русский",
    }/*,
    de: {
        short: "De",
        full: "Deutsche",
    },
    fr: {
        short: "Fr",
        full: "Français",
    },
    es: {
        short: "Es",
        full: "Española",
    },
    ch: {
        short: "中文",
        full: "中文",
    }*/
}
export type AvailableLanguage = 'en' | 'ru';// | 'de' | 'fr' | 'es' | 'ch';

export default i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            supportedLngs: Object.keys(Languages),
            fallbackLng: {
                'en-US': ['en'],
                'en-GB': ['en'],
                'ru-RU': ['ru'],
                'default': ['en']
            },
            ns: [
                'app',
                'customers',
                'users',
                'countries',
                'cryptoVerification',
                'audit',
                'system',
            ],
            debug: false,//process.env.NODE_ENV === 'development',
            interpolation: {
                escapeValue: false
            }
        }
    )
