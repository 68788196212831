import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import {Customer, customersApi, CustomerVerificationStatus} from "../../../../../api/customer/customersApi";
import {useForm} from "react-hook-form";
import {NotificationManager} from "../../../../../ui-kit/notifications/NotificationManager";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";
import {Form, FormGroup, FormRow} from "../../../../../ui-kit/forms/Form";
import {FormInputText} from "../../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormCheckbox} from "../../../../../ui-kit/forms/FormInputs/FormCheckbox";
import {FormInputDate} from "../../../../../ui-kit/forms/FormInputs/FormInputDate";
import {FormSelect, FormSelectAsync} from "../../../../../ui-kit/forms/FormInputs/FormSelect";
import {countriesApi} from "../../../../../api/dictionaries/countries";
import {FormMultiSelect} from "../../../../../ui-kit/forms/FormInputs/FormSelect/FormMultiSelect";
import {getEnumItems} from "../../../../../ui-kit/utils/enumUtils";
import {SystemRole, VerificationState} from "../../../../../api/authApi/authBaseTypes";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {BRANDS_ITEMS} from "../../../../../api/types/brands";

export const CustomerMainInfo = () => {
    const {t} = useTranslation(["customers"])
    const {customer, setCustomer} = useOutletContext<{ customer: Customer, setCustomer: (customer: Customer) => void }>()

    const form = useForm<Customer>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: customer,
        //@ts-ignore
        //resolver: yupResolver(usersApi.schemas.common, {}, {})
    })


    const submit = (value: Customer) => {
        return customersApi.put(value).then((data) => {
            setCustomer(data)
        }, error => {
            console.log("Error", error)
            if (error.response && error.response.data) {
                error.response.data.messages.forEach((m: string) => {
                    NotificationManager.errorToast(m)
                })

            } else {
                console.log("Error during request", error)
            }
        })
    }

    return (
        <DefaultPageWrapper line={false}>

            <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
                <FormRow sizes={["1fr", "1fr"]}>
                <FormGroup title={t('customers:customer.form.mainInfo')}>
                    <FormInputText name="id" form={form} hidden={true}/>
                        <FormSelect name="brand"
                                    label={t('customers:customer.form.brand')}
                                    form={form}
                                    disabled={true}
                                    items={BRANDS_ITEMS} />
                        <FormInputText name="name"
                                       label={t('customers:customer.form.name')}
                                       form={form}
                        />
                        <FormInputText name="registrationNumber"
                                      label={t('customers:customer.form.registrationNumber')}
                                      form={form}
                        />
                        <FormSelectAsync name="residenceCountry"
                                         label={t('customers:customer.form.residenceCountry')}
                                         form={form}
                                         loadItems={countriesApi.loadCountries(10)}
                        />


                </FormGroup>

                <FormGroup title={t('customers:customer.form.verification')}>
                    <FormInputText name="verification.applicantId"
                                   label={t('customers:customer.form.applicantId')}
                                   form={form} />
                    <FormSelect name="verification.status"
                                label={t('customers:customer.form.verification')}
                                form={form}
                                items={getEnumItems(CustomerVerificationStatus)} />
                    <FormInputText name="verification.comments"
                                   label={t('customers:customer.form.comments')}

                                   form={form} />
                </FormGroup>
                </FormRow>
                <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} position={"right"} buttonLabels={{
                    translationNamespaces: ['app'],
                    save: 'app:common.forms.save',
                    saving: 'app:common.forms.saving'
                }}/>

            </Form>


        </DefaultPageWrapper>
    )
}
