import styled from "styled-components";
import { HEADER_HEIGHT } from "../../constants";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../globalState/currentUserState";
import { FONT_EXTRA_DISABLED, FONT_MAIN, PRIMARY_EXTRA, PRIMARY_EXTRA_HOVER} from "../../../ui-kit/style-constants";
import { IconUserCircle } from "../../../ui-kit/icons/IconUserCircle";

const Wrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    column-gap: 1.5rem;
    align-items: center;
    margin: 0.75rem;
`;

const PhotoIcon = styled.div`
    width: ${HEADER_HEIGHT - 1.5}rem;
    height: ${HEADER_HEIGHT - 1.5}rem;
    color: ${PRIMARY_EXTRA};
    fill: ${ PRIMARY_EXTRA };
    
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PhotoImage = styled.div<{photo: string}>`
    width: ${HEADER_HEIGHT - 1.5}rem;
    height: ${HEADER_HEIGHT - 1.5}rem;
    border-radius: ${HEADER_HEIGHT}rem;
    border: 2px solid ${PRIMARY_EXTRA_HOVER};
    background: url("${({photo}) => photo}");
    background-size: cover;
`;

const Photo: FC<{photo ?: string}> = ({photo}) => {
    return (
        photo ?
            <PhotoImage photo={photo} /> :
            <PhotoIcon>
                <IconUserCircle />
            </PhotoIcon>
    )
}

const Info = styled.div`
    font-size: 1rem;
    font-weight: 300;
    display: grid;
    grid-gap: 0.25rem;
    width: 15rem;
`;

const Name = styled.div`
    color: ${ FONT_MAIN };
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Customer = styled.div`
    color: ${ FONT_EXTRA_DISABLED };
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const UserInfo = () => {

    const user = useRecoilValue(currentUserState)

    return (
        <Wrapper>
            <Photo photo={ user?.photoUrl } />
            <Info>
                <Name>{ user?.name } { user?.lastName }</Name>
                <Customer>{ user?.email }</Customer>
            </Info>
        </Wrapper>
    )

}
