import {ICreateForm} from "../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Form, FormRow} from "../../../ui-kit/forms/Form";
import {FormInputText} from "../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSelect, FormSelectAsync} from "../../../ui-kit/forms/FormInputs/FormSelect";
import {FormSaveButton} from "../../../ui-kit/forms/FormSaveButton";
import {CryptoCurrency, cryptoVerificationApi, CryptoVerificationRq} from "../../../api/authApi/cryptoVerificationApi";
import {useEffect, useState} from "react";
import {customersApi} from "../../../api/customer/customersApi";

interface CurrencyView {
    code: string,
    name: string
}

export const CryptoVerificationCreateForm = ({entity, submit}: ICreateForm<CryptoVerificationRq>) => {
    const {t} = useTranslation(['customers'])

    const [cryptoCurrencies, setCryptoCurrencies] = useState<CryptoCurrency[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyView[]>([]);
    const [tokens, setTokens] = useState<CryptoCurrency[]>([]);

    const form = useForm<CryptoVerificationRq>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        defaultValues: {
            direction: 'Deposit',
        },
        //@ts-ignore
        resolver: yupResolver(cryptoVerificationApi.schemas.create, {}, {}),
    })

    const loadCurrencies = () => {
        cryptoVerificationApi.currencies().then(
            response => {
                console.log(response)
                setCryptoCurrencies(response)
            },
            err => console.log(err)
        )
    }

    useEffect(() => {
        loadCurrencies()
    }, [])

    useEffect(() => {
        setCurrencies(cryptoCurrencies.filter(it => it.tokenId == 0).map(it => ({code: it.currency, name: it.name})))
    }, [cryptoCurrencies])


    const changeCurrency = (currency: string | undefined) => {
        if (currency) {
            setTokens(cryptoCurrencies.filter(it => it.currency == currency && it.tokenId != 0))
        } else {
            setTokens([])
        }
        form.setValue('tokenId', 0)
    }

    useEffect(() => {
        const subs = form.watch((data, {name}) => {
            if (name === "currency" && data && Object.keys(data).length > 0) {
                changeCurrency(data.currency)
            }
            if (name == "direction" && data.direction == "Withdrawal") {
                form.setValue('transactionHash', "")
            }
        })
        return () => subs.unsubscribe()
    }, [form.watch, cryptoCurrencies])

    const direction = form.watch("direction")

    return (
        <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
            <FormRow sizes={['1fr', '1fr', '1fr', '1fr']}>
                <FormSelectAsync name="customer"
                                 label={t('cryptoVerification:form.customer')}
                                 form={form}
                                 loadItems={customersApi.loadCustomers(10)}
                />

                <FormSelect name="direction"
                            label={t('cryptoVerification:form.direction.name') as string}

                            items={[
                                {value: "Withdrawal", label: t("cryptoVerification:form.direction.withdrawal")},
                                {value: "Deposit", label: t("cryptoVerification:form.direction.deposit")},
                            ]}
                            form={form}
                />

                <FormSelect name="currency"
                            label={t('cryptoVerification:form.currency') as string}
                            items={
                                currencies.map(it => ({value: it.code, label: `${it.code} - ${it.name}`}))
                            }
                            form={form}
                />

                <FormSelect name="tokenId"
                            label={t('cryptoVerification:form.token') as string}
                            items={
                                [
                                    {value: 0, label: "-"},
                                    ...tokens.map(it => ({value: it.tokenId, label: `${it.symbol} - ${it.name}`}))
                                ]
                            }
                            form={form}
                />
            </FormRow>
            <FormRow sizes={["2fr", "3fr"]}>
                <FormInputText label={t('cryptoVerification:form.address') as string}
                               name="addressHash"
                               form={form}/>

                <FormInputText label={t('cryptoVerification:form.transaction') as string}
                               name="transactionHash"
                               disabled={direction === "Withdrawal"}
                               form={form}/>
            </FormRow>

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ['cryptoVerification'],
                save: 'cryptoVerification:form.verify',
                saving: 'cryptoVerification:form.verifying'
            }}/>
        </Form>


    )
}
