import {useTranslation} from "react-i18next";
import {countriesApi, Country} from "../../../../../api/dictionaries/countries";
import {CountryForm} from "./countryForm";
import {DefaultFilterForm, DefaultFilterInstance, TablePage} from "../../../../../ui-kit/pages/TablePage";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";

export const CountriesPage = () => {
    const {t} = useTranslation(["system", "app"])

    return <DefaultPageWrapper>
        <TablePage
            api={countriesApi}
            columns={[
                {id: "code", name: t('system:dictionaries.countries.code') as string,},
                {id: "iso2", name: t('system:dictionaries.countries.iso2') as string},
                {id: "iso3", name: t('system:dictionaries.countries.iso3') as string},
                {id: "name", name: t('system:dictionaries.countries.name') as string},
                {id: "prohibited", name: t('system:dictionaries.countries.prohibited') as string, render: (el: Country) => el.prohibited ? t('system:dictionaries.countries.yes') : t('system:dictionaries.countries.no')},
            ]}
            filterForm={DefaultFilterForm}
            initFilter={DefaultFilterInstance}
            editFormTitle={t('system:dictionaries.countries.titleSingle')}
            editForm={CountryForm}
            translations={["system", "app"]}
            pageSize={10}
        />
    </DefaultPageWrapper>
}

