import {useTranslation} from "react-i18next";
import {ModuleMenu} from "../../components/ModuleMenu";
import {Outlet} from "react-router-dom";

export const System = () => {

    const {t} = useTranslation(["system"])

    return (
        <>
            <ModuleMenu elements={ [
                { tag: t("system:menu.dictionaries.title"), link: "dictionaries" },
            ]} />
            <Outlet />
        </>
    )
}
