import { useEffect } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { fingerPrintState } from "../globalState/fingerPrintState";
import { useSetRecoilState } from "recoil";

export const FingerPrintLoader = () => {
    const setFingerPrint = useSetRecoilState(fingerPrintState);

    useEffect(() => {
        FingerprintJS.load()
            .then((fp) => fp.get())
            .then((result) => {
                setFingerPrint(result);
            }, error => {
                console.log('Fingerprint load error', error)
                setFingerPrint({visitorId: "not-detected"});
            });
    }, [setFingerPrint]);

    return null
}
