export const HEADER_HEIGHT = 5;

export const SESSION_STORAGE_TOKEN_ACCESS = "legex-auth-access"
export const SESSION_STORAGE_TOKEN_REFRESH = "legex-auth-refresh"
export const SESSION_STORAGE_FINGERPRINT = "legex-auth-device-fingerprint"

export const PADDING_MOBILE_DEVICE = 0.5;
export const HEADER_HEIGHT_DEFAULT = 5;
export const CONTENT_WIDTH_DEFAULT = 70;
export const CONTENT_WIDTH_MIN = 20;
export const CONTENT_HORIZONTAL_PADDING = 2;
export const MOBILE_MAX_WIDTH = '910px';

export const REFRESH_TOKEN_LAG = 5000 // ms

export const ILLEGAL_COUNTRIES = [
    "ALB",
    "BRB",
    "BFA",
    "KHM",
    "CYM",
    "HTI",
    "JAM",
    "JOR",
    "MLI",
    "MAR",
    "MMR",
    "NIC",
    "PAK",
    "PAN",
    "PHL",
    "SEN",
    "SSD",
    "SYR",
    "TUR",
    "UGA",
    "YEM",
    "ZWE",
    "AFG",
    "BHS",
    "BWA",
    "PRK",
    "GHA",
    "IRN",
    "IRQ",
    "TTO",
    "VUT",
    "BLR",
]
