import i18n from "i18next"
import '../../../i18n';
import { Languages } from "../../../i18n";
import { Component } from "react";
import Select from "react-select";

import { StylesConfig } from "react-select";
import {
    FONT_EXTRA,
    FONT_MAIN,
    FONT_MAIN_DISABLED,
    PRIMARY,
    PRIMARY_EXTRA
} from "../../../ui-kit/style-constants";
import {lighten} from "polished";

const fullNames = Object.keys(Languages).map((it) => (
    {value: it, label: Languages[it].full}
));

const shortNames = Object.keys(Languages).map((it) => (
    {value: it, label: Languages[it].short}
));


interface ILanguageSelectorState {
    language: string
}

interface ILanguageSelectorProps {
    langName?: 'full' | 'short',
    menuHorizontalPosition?: 'left' | 'right';
}

const customStyling: (position: 'left' | 'right') => StylesConfig = (position) => ({
    container: (provided, state) => ({
        ...provided,
        outline: 'red !important'
    }),
    control: (provided, state) => {
        return {
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            cursor: 'pointer',
            border: 0,
            background: 'transparent',
            outline: 'white !important',
            flex: 1,
            '&:hover': {
                '${IndicatorStyled}': {
                    color: '${FONT_MAIN} !important'
                },
                '${SingleValue}': {
                    color: '${FONT_MAIN} !important'
                }

            }
        }
    },
    indicatorSeparator: (provided, state) => {
        return {
            display: 'none'
        }
    },
    dropdownIndicator:  (provided, state) => {
        return {
            ...provided,
            padding: '0',
            color: FONT_MAIN_DISABLED
        }
    },
    option: (provided, state) => {
        return {
            ...provided,
            padding: '0.5rem 1rem',
            cursor: 'pointer',
            outline: 'none',
            position: 'relative',
            color: state.isSelected && state.isFocused ? FONT_MAIN : FONT_EXTRA,
            backgroundColor: state.isFocused
                ? (state.isSelected ? PRIMARY_EXTRA : lighten(0.2, PRIMARY_EXTRA))
                : (state.isSelected ? PRIMARY : 'transparent')
        }
    },
    singleValue: (provided, state) => {
        return {
            ...provided,
            color: FONT_MAIN_DISABLED,
            fontSize: '0.9rem',
            wordBreak: 'keep-all',
            userSelect: 'none',
            marginLeft: 0,
            marginRight: 0,
            transform: 'none',
            top: 0,
            position: 'initial',
            overflow: 'initial',
            maxWidth: 'initial'
        }
    },
    menu: (provided, state) => {
        return position === 'left' ? {
            ...provided,
            width: 'initial',
            minWidth: '100%'
        } : {
            ...provided,
            width: 'initial',
            minWidth: '100%',
            right: 0
        }
    }
    /*Menu: this.props.menuHorizontalPosition === 'left' ? MenuLeft : MenuRight,*/
})


// TODO: refactor that
export class LanguageSelector extends Component<ILanguageSelectorProps, ILanguageSelectorState> {

    state = {
        language: i18n.language || 'en'
    }

    change = (lng: string) => this.changeLanguage(lng);

    componentDidMount() {
        i18n.on('languageChanged', this.change)
    }

    componentWillUnmount() {
        i18n.off('languageChanged', this.change);
    }

    changeLanguage(lng: string) {
        this.setState({language: lng});
    }

    render() {
        return (
            <Select
                value={ (this.props.langName === 'full' ? fullNames : shortNames).find(it => it.value === this.state.language) }
                options={ fullNames }
                isSearchable={ false }
                openMenuOnClick={ true }
                menuPlacement="top"
                styles={customStyling(this.props.menuHorizontalPosition || 'right')}
                onChange={ (it: any) => {
                    i18n.changeLanguage(it?.value || 'en')
                } }
            />
        )
    }
}

