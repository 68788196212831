import { axios } from "../axios";

export const timeOtpApi = {
    initiate: async (data: { password: string }) => {
        return axios.post<{ uri: string }>(
            "/auth/v1/time-otp",
            data
        )
    },
    approve: async (data: { password: string, code: string }) => {
        return axios.post(
            "/auth/v1/time-otp/approve",
            data
        )
    },
    delete: async (data: { password: string, code: string }) => {
        return axios.delete(
            "/auth/v1/time-otp",
            {
                data: data
            }
        )
    },
}
