import {ICreateForm, IEditForm} from "../../../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Form} from "../../../../../ui-kit/forms/Form";
import {FormSelect, FormSelectAsync} from "../../../../../ui-kit/forms/FormInputs/FormSelect";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {CustomerAddUserRequest, customersApi, CustomerUserRole, customerUsersApi} from "../../../../../api/customer/customersApi";
import {getEnumItems} from "../../../../../ui-kit/utils/enumUtils";
import {usersApi} from "../../../../../api/authApi/usersApi";
import {yupResolver} from "@hookform/resolvers/yup";

export const CustomerUserCreateForm = ({entity, submit}: ICreateForm<CustomerAddUserRequest>) => {
    const {t} = useTranslation(['customers'])

    const form = useForm<CustomerAddUserRequest>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(customerUsersApi.schemas.create, {}, {})
    })

    return (
        <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
            <FormSelectAsync name="user"
                             label={t("customers:users.form.user")}
                             form={form}
                             loadItems={usersApi.loadUsers(10)}
            />

            <FormSelect name="role"
                        label={t("customers:users.form.role")}
                        form={form}
                        items={getEnumItems(CustomerUserRole)}
            />

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ['app'],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            }}/>
        </Form>

    )
}
