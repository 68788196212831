import { CSSProperties, ReactNode } from "react";

export type NotificationKind = 'info' | 'success' | 'warning' | 'error'

export interface NotificationProps {
    id: string,
    kind: NotificationKind,
    title: string,
    content: ReactNode,
    delay?: number | undefined,
    type: 'toast' | 'line',
}

export type CreateReadyNotification = Omit<NotificationProps, "id">
export type SimpleNotification = Omit<CreateReadyNotification, "type">


export const TRANSITION_STYLES: {[key: string ]: CSSProperties} = {
    entered: {
        opacity: 1,
    },
    entering: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    exited: {
        opacity: 0,
    },
    exiting: {
        opacity: 0,
        transform: `scale(0.9)`,
    },
};

export const TRANSITION_DURATION = 250;
