import styled from "styled-components";
import React, {PropsWithChildren, ReactElement, ReactNode} from "react";
import { FONT_EXTRA_DISABLED, FONT_MAIN, WARNING_HOVER } from "../../../ui-kit/style-constants";
import { IconEdit } from "../../../ui-kit/icons/IconEdit";

const EditWrapper = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;

    svg {
        height: 1rem;
        fill: ${ FONT_MAIN };
    }

    [disabled] {
        cursor: initial;
        fill: ${ FONT_EXTRA_DISABLED };
    }


    &:hover, &:focus {
        :not([disabled]) {
            svg {
                fill: ${ WARNING_HOVER };
            }
        }
    }
`;

export const IconButton = (props: PropsWithChildren<React.ButtonHTMLAttributes<any>>) => {
    return <EditWrapper { ...props }>
        { props.children }
    </EditWrapper>
}
