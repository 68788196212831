import { atom } from "recoil";
import { SESSION_STORAGE_FINGERPRINT } from "../constants";

interface IFingerPrint {
    visitorId: string;
}

export const fingerPrintState = atom<IFingerPrint | undefined>({
    key: 'fingerPrint',
    default: undefined,
    effects_UNSTABLE: [
        ({onSet}) => {
            onSet(data => {
                if (data) {
                    sessionStorage.setItem(SESSION_STORAGE_FINGERPRINT, data.visitorId)
                } else {
                    sessionStorage.removeItem(SESSION_STORAGE_FINGERPRINT)
                }
            })
        }
    ]
});
