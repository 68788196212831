export const PRIMARY = "#103452";
export const PRIMARY_HOVER = "#113c5f";
export const PRIMARY_DISABLED = "#5d6d7b";

export const PRIMARY_EXTRA = "#2c6ec4";
export const PRIMARY_EXTRA_HOVER = "#257ced";
export const PRIMARY_EXTRA_DISABLED = "#4e7dbb";

export const WARNING = "#ed9d51";
export const WARNING_HOVER = "#ee953e";
export const WARNING_DISABLED = "#cf955f";

export const DANGER = "#d82d3f";
export const DANGER_HOVER = "#f1162e";
export const DANGER_DISABLED = "#c65a65";


export const BG_MAIN = "#FFFFFF";
export const BG_MAIN_ALT = "#EDF2F9";
export const BG_MAIN_ALTER = "#d8dce2";
export const BG_MAIN_ALTEREST = "#b1b1b9";
export const BG_EXTRA = "#454545";
export const BG_EXTRA_ALT = "#cdcdcd";

export const INPUT_BG = "#fff";
export const INPUT_BG_DISABLED = "#e5e5e5";

export const FONT_MAIN = "#000";
export const FONT_MAIN_ALT = "#282828";
export const FONT_MAIN_DISABLED = "#333";
export const FONT_EXTRA = "#fff";
export const FONT_EXTRA_ALT = "#dad8dc";
export const FONT_EXTRA_DISABLED = "#a1a0ad";

export const OUTLINE_PRIMARY = `0 0 0.25rem 0.01rem ${PRIMARY}`
export const OUTLINE_DANGER = `0 0 0.25rem 0.01rem ${DANGER}`

