import {ComponentType, Suspense, SuspenseProps} from "react";
import {Spinner} from "../../ui-kit/components/Spinner";

export const withSuspense = <P extends object>(Component: ComponentType<P>, fallback: SuspenseProps["fallback"] = <Spinner size={"xs"}/>) => ({
    ...props
}: P) => <Suspense fallback={fallback}>
    {
        //@ts-ignore
        <Component {...props} />
    }
</Suspense>
