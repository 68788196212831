import {accountsApi, CustomerAccount} from "../../../../../api/customer/accountsApi";
import {IEditForm} from "../../../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Form, FormGroup, FormRow} from "../../../../../ui-kit/forms/Form";
import {FormInputText} from "../../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSelectAsync} from "../../../../../ui-kit/forms/FormInputs/FormSelect";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {currenciesApi, CurrencyKind} from "../../../../../api/dictionaries/currencies";
import {useState} from "react";
import {Checkbox} from "../../../../../ui-kit/inputs";

export const CustomerAccountEditForm = ({entity, submit}: IEditForm<CustomerAccount>) => {
    const {t} = useTranslation(['customers'])
    const [correspondent, setCorrespondent] = useState<boolean>(entity?.details?.fiat?.correspondent != null)

    const form = useForm<CustomerAccount>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(accountsApi.schemas.common, {}, {})
    })

    const loadCurrencies = async (filter: string, page: number) => {
        const result = await currenciesApi.list({filter: filter}, page, 10)
        return {
            items: result.items.map(el => ({
                id: el.id,
                description: `${el.code} - ${el.name}`,
                kind: el.kind
            })),
            hasMore: result.hasMore
        }
    }
    
    return (
        <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
            <FormInputText name="id" form={form} hidden={true}/>
            <FormInputText name="customer" form={form} hidden={true}/>
            <FormInputText name="kind" form={form} hidden={true}/>

            <FormRow sizes={["2fr", "1.5fr"]}>

                <FormInputText name="name"
                               label={t("customers:accounts.name")}
                               form={form}
                />
                <FormSelectAsync name={"currency"}
                                 label={t("customers:accounts.currency")}
                                 loadItems={loadCurrencies}
                                 onChange={val => val ? form.setValue('kind', val.kind) : form.resetField('kind')}
                                 form={form}
                />

            </FormRow>

            <FormInputText name="accountNumber"
                           label={t("customers:accounts.accountNumber")}
                           form={form}
            />


            {
                form.watch().kind === CurrencyKind.FIAT && <FormGroup title={t("customers:accounts.fiat.title")}>
                    <FormRow sizes={["auto", "10rem"]}>
                        <FormInputText name="details.fiat.bank.name"
                                       label={t("customers:accounts.fiat.bankName")}
                                       form={form}
                        />
                        <FormInputText name="details.fiat.bank.id"
                                       label={t("customers:accounts.fiat.bankIdentifier")}
                                       form={form}
                        />
                    </FormRow>

                    <FormInputText name="details.fiat.bank.address"
                                   label={t("customers:accounts.fiat.bankAddress")}
                                   form={form}
                    />

                    <Checkbox checked={correspondent}
                              onChange={(v) => {
                                  console.log(v)
                                  setCorrespondent(v.target.checked)
                              }}
                              label={t("customers:accounts.fiat.correspondent")} />

                    { correspondent &&
                        <FormGroup title={t('customers:accounts.fiat.correspondent')}>

                            <FormInputText name="details.fiat.correspondent.account"
                                           label={t("customers:accounts.fiat.correspondentAccount")}
                                           form={form}
                            />
                            <FormRow sizes={["auto", "10rem"]}>
                                <FormInputText name="details.fiat.correspondent.bank.name"
                                               label={t("customers:accounts.fiat.bankName")}
                                               form={form}
                                />
                                <FormInputText name="details.fiat.correspondent.bank.id"
                                               label={t("customers:accounts.fiat.bankIdentifier")}
                                               form={form}
                                />
                            </FormRow>

                            <FormInputText name="details.fiat.correspondent.bank.address"
                                           label={t("customers:accounts.fiat.bankAddress")}
                                           form={form}
                            />
                        </FormGroup>
                    }

                    <FormInputText name="details.fiat.remark"
                                   label={t("customers:accounts.fiat.remark")}
                                   form={form}
                    />

                </FormGroup>
            }

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ['app'],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            }}/>
        </Form>


    )
}
