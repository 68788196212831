import { axios } from "../axios";

export interface VerificationToken {
    token: string
}

export const verificationApi = {
    identity: async () => {
        return axios.post<VerificationToken>(`/auth/v1/verification/identity`)
    },
    residence: async () => {
        return axios.post<VerificationToken>(`/auth/v1/verification/residence`)
    },
    actualize: async () => {
        return axios.post(`/auth/v1/verification/actualize`)
    }
}
