
export interface ApiPage<T> {
    hasMore: boolean,
    page: number,
    size: number,
    items: T[]
}

export const EMPTY_API_PAGE: ApiPage<any> = {
    items: [],
    page: 0,
    size: 0,
    hasMore: false
}
