import Axios, {AxiosError, AxiosHeaders, AxiosRequestConfig, InternalAxiosRequestConfig} from "axios";
import { SessionDataHook } from "../app/globalState/sessionDataState";
import { ApiError } from "./types/apiError";
import { toast } from "../ui-kit/notifications/NotificationManager";
import { SESSION_STORAGE_FINGERPRINT, SESSION_STORAGE_TOKEN_ACCESS } from "../app/constants";

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
    },
});

const requestHandler = (request: InternalAxiosRequestConfig) => {
    const token = sessionStorage.getItem(SESSION_STORAGE_TOKEN_ACCESS);
    const deviceFingerprint = sessionStorage.getItem(SESSION_STORAGE_FINGERPRINT);

    if (!request.headers) {
        request.headers = new AxiosHeaders()
    }
    if (token) {
        request.withCredentials = true
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    if (deviceFingerprint) {
        request.headers['X-Device-Fingerprint'] = deviceFingerprint;
    }

    return request;
};

axios.interceptors.request.use(requestHandler);

export const getAxiosErrorHandler = (sessionDataHook: SessionDataHook) => (error?: AxiosError<ApiError>) => {

    console.log("Interceptor axios", error)
    const [, setSessionData] = sessionDataHook

    const status = error?.response?.status;

    if (!status) {
        /*toastr.error(errorMessages.network, null, {
            messageClass: TOASTR_ERROR_VIEW,
        });
        return Promise.reject(errorMessages.network);*/
    }

    if (status === 403) {
       /* history.push(ROUTE_PATH_ROOT);
        toastr.error(errorMessages.forbidden, null, {
            messageClass: TOASTR_ERROR_VIEW,
        });
        return Promise.reject(error);*/
    }

    //const hasNoToken = !localStorage.getItem(SESSION_STORAGE_TOKEN_ACCESS);

    //const isTokenExpired = isAfter(new Date(), userStateAPI.state?.exp);

    if (status === 401/* || isTokenExpired || hasNoToken*/) {
        setSessionData(undefined)
    }

    return Promise.reject(error);
}


/*
export const getAxiosErrorHandler = (userStateAPI: AppStateSliceAPI<AppState['auth']>) => (error: APIError) => {
    const status = error?.response?.status;

    if (!status) {
        toastr.error(errorMessages.network, null, {
            messageClass: TOASTR_ERROR_VIEW,
        });
        return Promise.reject(errorMessages.network);
    }

    if (status === REQ_STATUS_FORBIDDEN) {
        history.push(ROUTE_PATH_ROOT);
        toastr.error(errorMessages.forbidden, null, {
            messageClass: TOASTR_ERROR_VIEW,
        });
        return Promise.reject(error);
    }

    const hasNoToken = !localStorage.getItem(LS_ITEM_NAME_TOKEN);
    const isTokenExpired = isAfter(new Date(), userStateAPI.state?.exp);

    if (status === REQ_STATUS_UNAUTHORIZED || isTokenExpired || hasNoToken) {
        localStorage.removeItem(LS_ITEM_NAME_TOKEN);
        userStateAPI.setState(null);
    }

    return Promise.reject(error);
};
*/


export type ErrorConfig = {
    [code: string] : undefined | string | {type: 'error' | 'warning', header: string, message: string} |  ((error ?: ApiError) => void)
    default ?: string | {type: 'error' | 'warning', header: string, message: string} |  ((error ?: ApiError) => void)
}

export type ErrorsConfig = {
    t: any
    [key: number]: ErrorConfig
    default ?: ErrorConfig
}

/**
 * Process errors
 * @param config example: {
 *   t: t,
 *   422: {
 *     "ALREADY_VERIFIED": () => {
 *       setVerified(true)
 *       console.log("already verified")
 *     },
 *   }
 * }
 */
export const errorProcessor = (config : ErrorsConfig) => {
    return (error: AxiosError<ApiError | undefined>) => {
        if (error.response) {

            const customConfig = (config && config[error.response.status]) || (config && config.default)
            if (customConfig) {
                const customProcessor = error.response.data ? customConfig[error.response.data?.error] : customConfig.default
                if (customProcessor) {
                    if (typeof customProcessor === 'string') {
                        toast({
                            kind: 'error',
                            title: config.t('app:errors.headers.error') as string,
                            content: customProcessor
                        })
                        return
                    } else if (typeof customProcessor === 'function') {
                        customProcessor(error.response.data)
                        return
                    } else {
                        toast({
                            kind: customProcessor.type,
                            title: customProcessor.header,
                            content: customProcessor.message
                        })
                        return
                    }
                }
            }

            if (error.response.status === 500) {
                toast({
                    kind: 'error',
                    title: config.t('app:errors.headers.error') as string,
                    content: 'Unexpected server error'
                })
            } else if (error.response.data) {
                toast({
                    kind: 'error',
                    title: config.t('app:errors.headers.error') as string,
                    content: error.response.data?.messages
                })
            } else {
                // TODO: make smth better
                toast({
                    kind: 'error',
                    title: config.t('app:errors.headers.error') as string,
                    content: error.response.statusText
                })
            }


        } else {
            console.log('Unexpected axios error', error.config)
            if (error.request) {
                console.log('Request', error.request);
            } else {
                console.log('Error message', error.message);
            }
        }
    }
}
