import { axios } from "../axios";
import * as yup from "yup";
import { ILLEGAL_COUNTRIES } from "../../app/constants";
import { ApiPage } from "../types/apiPage";
import {DefaultFilter} from "../../ui-kit/pages/TablePage";
import {ApiReference} from "../types/apiReference";
import {Country} from "../dictionaries/countries";
import {Customer} from "./customersApi";
import {CurrencyKind} from "../dictionaries/currencies";
import {CustomerSubEntityDefaultFilter} from "./customerSubEntityFilter";

export interface CustomerDeal {
    id: string;
    createdAt: string,
    updatedAt: string,
    customer: string,
    dealType: CustomerDealType,
    dealNumber: string,
    dealDate: Date,
    status: CustomerDealStatus,
    remark: string,
    details: {
        otc?: {
            subtype: CustomerDealOtcSubtype,
            currencySell: ApiReference<string>,
            currencyBuy: ApiReference<string>,
        }
    },
    fees: {
        type: CustomerDealFeeType,
        amount: number
        remark?: string
    }[]

}

export enum CustomerDealFeeType {
    BASIC = "Basic",
    AGENT = "Agent",
    EXTRA = "Extra"
}

export enum CustomerDealType {
    OTC = "OTC",
}

export enum CustomerDealStatus {
    CREATED = "Created",
    NEGOTIATE = "Negotiate",
    CONFIRMED = "Confirmed",
    APPROVED = "Approved",
    REJECTED = "Rejected",
    SIGNED = "Signed",
    IN_WORK = "InWork",
    HOLD = "Hold",
    CANCELED = "Canceled",
    CLOSED = "Closed",
}

export enum CustomerDealOtcSubtype {
    CRYPTO_BUY = "CryptoBuy",
    CRYPTO_SELL = "CryptoSell",
}

export const dealsApi = {
    BASE_URL: "/contracts/v1/deals",
    schemas: {
        common: yup.object({
            customer: yup.string()
                .required('required'),
            dealType: yup.string()
                .required('required'),
            dealNumber: yup.string()
                .required('required'),
            dealDate: yup.date()
                .required('required'),
            status: yup.string()
                .required('required'),
            remark: yup.string(),
            details: yup.object()
                .default(undefined)
                .shape({
                    otc: yup.object()
                        .notRequired()
                        .default(undefined)
                        .shape({
                            subtype: yup.string()
                                .required('required'),
                            currencySell: yup.object()
                                .default(undefined)
                                .required('required')
                                .shape({
                                    id: yup.string()
                                        .required('required')
                                }),
                            currencyBuy: yup.object()
                                .default(undefined)
                                .required('required')
                                .shape({
                                    id: yup.string()
                                        .required('required')
                                })
                        })
                }),
            fees: yup.array()
                .of(
                    yup.object()
                        .default(undefined)
                        .shape({
                            type: yup.string()
                                .required('required'),
                            amount: yup.number()
                                .required('required'),
                            remark: yup.string()
                        })
                )

        })
    },

    list: async (filter?: CustomerSubEntityDefaultFilter, page: number = 0, size: number = 25) => {
        return (await axios.get<ApiPage<CustomerDeal>>(
            dealsApi.BASE_URL,
            {
                params: {
                    customer: filter?.customer?.id,
                    filter: filter?.filter,
                    page: page,
                    size: size
                }
            })).data
    },

    get: async (id: string) => (await axios.get<CustomerDeal>(`${dealsApi.BASE_URL}/${id}`)).data,

    post: async (deal: CustomerDeal) => (await axios.post<CustomerDeal>(dealsApi.BASE_URL, deal)).data,

    put: async (deal: CustomerDeal) => (await axios.put<CustomerDeal>(`${dealsApi.BASE_URL}/${deal.id}`, deal)).data,

    delete: async (id: string) => (await axios.delete(`${dealsApi.BASE_URL}/${id}`)),

}
