import styled, { css } from "styled-components";
import { ButtonHTMLAttributes, FC } from "react";
import { BG_MAIN_ALT, BG_EXTRA_ALT, BG_EXTRA, DANGER, DANGER_DISABLED, DANGER_HOVER, PRIMARY, PRIMARY_DISABLED, PRIMARY_HOVER, FONT_EXTRA_DISABLED, FONT_EXTRA_ALT, FONT_MAIN, PRIMARY_EXTRA, PRIMARY_EXTRA_DISABLED, PRIMARY_EXTRA_HOVER, WARNING, WARNING_DISABLED, WARNING_HOVER, FONT_EXTRA, FONT_MAIN_ALT, FONT_MAIN_DISABLED, BG_MAIN, BG_MAIN_ALTER } from "../../style-constants";

type ButtonColor = 'primary' | 'secondary' | 'warning' | 'danger' | 'extra' | 'ghost'
type ButtonSize = 'xl' | 'xs'

const colors = {
    primary: {
        main: {
            back: PRIMARY,
            color: FONT_EXTRA,
        },
        hover: {
            back: PRIMARY_HOVER,
        },
        disabled: {
            back: PRIMARY_DISABLED,
            color: FONT_EXTRA_DISABLED
        }
    },
    secondary: {
        main: {
            back: BG_MAIN_ALT,
            color: FONT_MAIN,
        },
        hover: {
            back: BG_MAIN_ALTER,
        },
        disabled: {
            back: BG_MAIN,
            color: FONT_EXTRA_DISABLED
        }
    },
    warning: {
        main: {
            back: WARNING,
            color: FONT_EXTRA,
        },
        hover: {
            back: WARNING_HOVER,
        },
        disabled: {
            back: WARNING_DISABLED,
            color: FONT_EXTRA_ALT
        }
    },
    danger: {
        main: {
            back: DANGER,
            color: FONT_EXTRA,
        },
        hover: {
            back: DANGER_HOVER,
        },
        disabled: {
            back: DANGER_DISABLED,
            color: FONT_EXTRA_ALT
        }
    },
    extra: {
        main: {
            back: PRIMARY_EXTRA,
            color: FONT_EXTRA,
        },
        hover: {
            back: PRIMARY_EXTRA_HOVER,
        },
        disabled: {
            back: PRIMARY_EXTRA_DISABLED,
            color: FONT_EXTRA_DISABLED
        }
    },
    ghost: {
        main: {
            back: "transparent",
            color: FONT_MAIN,
        },
        hover: {
            back: BG_MAIN_ALTER,
        },
        disabled: {
            back: "transparent",
            color: FONT_EXTRA_DISABLED
        }
    },
}

const ButtonComponent = styled.button<{color: ButtonColor, size: ButtonSize}>`

    display: inline-flex;
    justify-content: center;
    align-items: center;
    
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    white-space: nowrap;
    
    ${({size}) => size === 'xl' ? css`
        height: 2.5rem;
        padding: 0.75rem 1.5rem;
        font-size: 0.9rem;
        font-weight: 300;
    ` : css`
        height: 2rem;
        padding: 0.5rem 0.75rem;
        font-size: 0.9rem;
        font-weight: 300;
    `}
    line-height: 1rem;

    &[disabled] {
      cursor: initial;
    }
    
    ${({color}) => {
        const style = colors[color]
        
        return css`
            background-color: ${style.main.back};
            color: ${style.main.color};

            &[disabled] {
                background-color: ${style.disabled.back};
                color: ${style.disabled.color};
            }
            
            &:hover, &:focus {
                &:not([disabled]) {
                    background-color: ${style.hover.back};
                }
            }
        `
    }}
    
`;

export interface IButtonProps extends ButtonHTMLAttributes<any> {
    color?: ButtonColor,
    size?: ButtonSize,
    disabled?: boolean
}

export const Button: FC<IButtonProps> = (
    {
        children,
        size = 'xl',
        color = 'primary',
        ...props
    }
) => {
    return (
        <ButtonComponent size={size} color={color} type="button" {...props}>{children}</ButtonComponent>
    )
}
