import {axios} from "../axios";
import * as yup from "yup";
import {ChallengeKind, SystemRole, Verification} from "./authBaseTypes";
import {ApiPage} from "../types/apiPage";
import {DefaultFilter} from "../../ui-kit/pages/TablePage";
import {ApiReference} from "../types/apiReference";

export interface UserList {
    id: string;
    brand: string;
    name: string;
    lastName: string;
    middleName: string;
    locked: boolean;
    birthDate: Date;
    email: string;
    residenceCountry: ApiReference<string>;
    phone: string;
    twoFactorKind: ChallengeKind;
    roles: SystemRole[];
    verification: Verification;
}

export const getUserFullName = (user: {
    name: string,
    middleName: string,
    lastName: string,
}) => [user.name, user.middleName, user.lastName].filter(it => !!it).join(" ")

export interface FullUser extends UserList {

}

export interface CreateUserRq {
    brand: string,
    name: string;
    lastName?: string;
    middleName?: string;
    locked: boolean;
    birthDate: Date;
    email: string;
    residenceCountry: ApiReference<string>;
    phone?: string;
    roles: SystemRole[];
}

export interface UpdateSelfRq {
    name: string,
    middleName: string,
    lastName: string,
}

export const usersApi = {
    schemas: {
        create: yup.object({
            brand: yup.string()
                .required('required'),
            email: yup.string()
                .required('required'),
            name: yup.string()
                .required('required'),
            lastName: yup.string(),
            middleName: yup.string(),
            locked: yup.boolean(),
            birthDate: yup.date(),
            phone: yup.string(),
            roles: yup.array()
                .of(
                    yup.string()
                ),
            residenceCountry: yup.object()
                .shape({
                    id: yup.string()
                        .required('required'),
                    description: yup.string(),
                    prohibited: yup.boolean()
                })
                .required('required')
                .test('illegal-country', 'failed', (value, {path, createError}) => {
                    return value?.prohibited ? createError({path, message: 'countryProhibited'}) : true
                })
        }),
    },

    BASE_URL: "/auth/v1/users",

    loadUsers: (pageSize: number) => async (filter: string, page: number) => {
        const result = await usersApi.list({filter: filter}, page, pageSize)
        return {
            items: result.items.map(el => ({
                id: el.id,
                description: `${getUserFullName(el)} (${el.email})`
            })),
            hasMore: result.hasMore
        }
    },

    list: async (filter: DefaultFilter, page: number = 0, size: number = 25) => {
        return (await axios.get<ApiPage<UserList>>(usersApi.BASE_URL, {
            params: {
                filter: filter?.filter,
                page: page,
                size: size
            }
        })).data
    },

    get: async (id: string) => {
        return (await axios.get<FullUser>(`${usersApi.BASE_URL}/${id}`)).data
    },

    post: async (rq: CreateUserRq) => {
        return (await axios.post<FullUser>(usersApi.BASE_URL, rq)).data
    },

    put: async (rq: FullUser) => {
        return (await axios.put<FullUser>(`${usersApi.BASE_URL}/${rq.id}`, rq)).data
    },

    delete: async (id: string) => {
        return (await axios.delete(`${usersApi.BASE_URL}/${id}`))
    },
}
