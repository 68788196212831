import { InputHTMLAttributes } from "react";
import { FieldError, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { IFormInput } from "../IFormInput";
import { resolveError } from "../utils";
import { InputText } from "../../../inputs";
import { FormInputWrapper, FormInputLabel, FormInputHint } from "../base";

export const FormInputText = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>({
    name,
    hint,
    label,
    form,
    disabled,
    placeholder,
    error = false,
    hidden,
    disableErrorHint = false,
    /*...props*/
}: IFormInput<TFieldValues, TContext> & Omit<InputHTMLAttributes<HTMLInputElement>, "name" | "form" | "type">) => {

    const {t} = useTranslation(["app"])

    const errors = form.formState.errors as { [key in keyof TFieldValues]: FieldError }
    const touchedFields = form.formState.touchedFields as { [key in keyof TFieldValues]: boolean }

    const errorHint = touchedFields[name] && resolveError(t, errors[name])

    const isError = touchedFields[name] && !!errors[name]?.type || error || false

    return (
        <FormInputWrapper hidden={hidden || false}>
            {
                !!label && <FormInputLabel htmlFor={ name }>{ label }</FormInputLabel>
            }
            <Controller control={ form.control as any }
                        name={ name }
                        render={ ({field}) => {
                            return <InputText value={ field.value }
                                              onChange={ e => field.onChange(e) }
                                              onBlur={ field.onBlur }
                                              disabled={ disabled || form.formState.isSubmitting }
                                              id={ name }
                                              name={ name }
                                              error={ isError }
                                              placeholder={ placeholder ? t(placeholder) as string : '' }
                                    />
                        }}
            />
            {
                !disableErrorHint && (hint || errorHint) && <FormInputHint error={ errorHint }>{ errorHint || hint }</FormInputHint>
            }

        </FormInputWrapper>
    )
}
