import { FieldError } from "react-hook-form";

export const resolveError = (t: any, error ?: FieldError) => {
    if (!error) return undefined;
    if (error.type === 'required') return t('app:errors.required')
    if (error.message) {
        if (error.message.match(/^[a-z]+:/)) {
            t()
        }
    }
    return error.message ? t(`app:errors.${ error.message }`) : undefined
}
