import { InputHTMLAttributes } from "react";
import { InputComponent } from "./base/InputComponent";

export const InputText = ({
    error = false,
    ...props
}: { error?: boolean } & Omit<InputHTMLAttributes<HTMLInputElement>, "type">) => {

    return (
        <InputComponent type={ 'text' }
                        error={ error || false }
                        { ...props } />
    )
}
