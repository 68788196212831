import { axios } from "../axios";
import * as yup from "yup";
import { validatePassword } from "../../utils/validators";

export interface InitiateChangePasswordRq {
    currentPassword: string,
    newPassword: string,
}
export interface ChangePasswordRq {
    currentPassword: string,
    newPassword: string,
    twoFactorCode: string
}

export interface ChangePasswordRqForm extends ChangePasswordRq {
    newPasswordConfirmation: string;
}

export const passwordApi = {
    schemas: {
        initiateSchema: yup.object({
            currentPassword: yup.string()
                .required('required'),
            newPassword: yup.string()
                .required('required')
                .test('check-password', 'failed', function (value) {
                    const {path, createError} = this
                    const error = validatePassword(value)
                    return error ? createError({path, message: error}) : true;
                }),
            newPasswordConfirmation: yup.string()
                .required('required')
                .test('confirmationFailed', 'confirmationFailed', function (value) {
                    const {parent: {newPassword}} = this
                    return newPassword === value
                }),
            twoFactorCode: yup.string()
                .required('required'),
        }),
    },
    getCode: async (data: InitiateChangePasswordRq) => {
        return axios.post(
            "/auth/v1/change-password",
            data
        )
    },
    changePassword: async (data: ChangePasswordRq) => {
        return axios.post(
            "/auth/v1/change-password",
            data
        )
    }
}
