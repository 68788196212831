import {StylesConfig} from "react-select";
import {SelectItem} from "./SelectItem";
import {
    FONT_EXTRA,
    FONT_MAIN, FONT_MAIN_DISABLED,
    INPUT_BG,
    INPUT_BG_DISABLED,
    OUTLINE_DANGER,
    OUTLINE_PRIMARY, PRIMARY,
    PRIMARY_HOVER
} from "../../style-constants";
import {darken, lighten} from "polished";

export const selectCustomStyling: (error: boolean, disabled: boolean) => StylesConfig<SelectItem<any>> = (error, disabled) => ({
    control: (provided, state) => {
        return {
            ...provided,
            border: `1px solid ${ FONT_MAIN }`,
            boxShadow: error ? OUTLINE_DANGER : state.menuIsOpen || state.isFocused ? OUTLINE_PRIMARY : 'none',
            borderRadius: "0.25rem",
            paddingLeft: "0.5rem",
            backgroundColor: disabled ? INPUT_BG_DISABLED : INPUT_BG,
            "&:hover": {
                border: `1px solid ${ FONT_MAIN }`,
            },
        }
    },
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isSelected ? PRIMARY_HOVER : state.isFocused ? PRIMARY : INPUT_BG,
            color: state.isSelected || state.isFocused ? FONT_EXTRA : FONT_MAIN,
            "&:active": {
                backgroundColor: state.isSelected ? lighten(0.1, PRIMARY_HOVER) : state.isFocused ? lighten(0.1, PRIMARY) : undefined
            }
        }
    },
    valueContainer: (provided) => {
        return {
            ...provided,
            fontSize: "0.9rem"
        }
    },
    singleValue: (provided) => {
        return {
            ...provided,
            color: disabled ? FONT_MAIN_DISABLED : FONT_MAIN
        }
    },
    indicatorSeparator: (provided) => {
        if (!disabled) {
            return provided
        } else {
            return {
                ...provided,
                backgroundColor: darken(0.1, INPUT_BG_DISABLED)
            }
        }
    },
})
