import { css } from 'styled-components';
import MobileDetect from 'mobile-detect';

import { PADDING_MOBILE_DEVICE } from '../app/constants';

const md = new MobileDetect(window.navigator.userAgent);
export const isMobile = !!md.mobile();

export const forceMobileFullWidth = () =>
    isMobile
        ? css`
            width: 100vw;
            margin-left: -${ PADDING_MOBILE_DEVICE }rem;
            margin-right: -${ PADDING_MOBILE_DEVICE }rem;
        `
        : '';
