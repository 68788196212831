import { FC, ReactNode, useEffect, useRef } from "react";
import { Overlay } from "../Overlay";
import ReactDOM from "react-dom";
import { KEY_CODES } from "../../utils/KEY_CODES";
import { IconTimes } from "../../icons/IconTimes";
import styled from "styled-components";
import { MouseEvent} from "react";
import { BG_MAIN, BG_MAIN_ALT, FONT_EXTRA_DISABLED, FONT_MAIN } from "../../style-constants";
import { transparentize } from "polished";
import Draggable from "react-draggable";

export type ModalSize = 'xxxl' | 'xxl' | 'xl' | 'xs' | 'xxs';

const SIZES = {
    xxxl: {
        width: '60rem',
        height: '5.25rem'
    },
    xxl: {
        width: '50rem',
        height: '5.25rem'
    },
    xl: {
        width: '40rem',
        height: '4.5rem',
    },
    xs: {
        width: '30rem',
        height: '3.5rem',
    },
    xxs: {
        width: '25rem',
        height: '3rem',
    }
}

interface IModalProps {
    /** Содержимое модального окна */
    children: ReactNode;
    /** Контейнер, в котором происходит размещение модального окна (BODY по умолчанию) */
    container?: Element;
    /** Заголовок */
    header: ReactNode;
    /** Размер */
    size?: ModalSize;
    /** Свойство, указывающее, открывается ли модальное окно при создании */
    opened?: boolean;
    /** Свойство, указывающее, закрывается ли модальное окно при нажатии Esc */
    shouldCloseOnEsc?: boolean;
    /** Свойство, указывающее, закрывается ли модальное окно при нажатии на Overlay.
     *  Общий случай клика вне Modal реализуется вне компонента - внутри это сделать
     *  в общем случае невозможно, так как модальное окно может содержать элементы,
     *  находящиеся вне его иерархии (например, выпадающие меню, DatePicker-календарь и т.п.)
     */
    shouldCloseOnOverlayClick?: boolean;
    /** Коллбэк закрытия */
    onRequestClose?: () => void;
}

const ModalComponent = styled.div<{
    width: string,
    height: string,
}>`
    display: flex;
    flex-direction: column;
    width: ${({width}) => width};
    min-height: ${({height}) => height};
    max-height: 90vh;
    background: ${BG_MAIN_ALT};
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem 0.05rem ${transparentize(0.7, FONT_MAIN)};
`;

const Header = styled.h2`
    position: relative;
    padding: 0.5rem 1rem;
    margin: 1rem;
`;

const IconClose = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    
    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: ${FONT_EXTRA_DISABLED};
    }
    
    &:hover,:focus {
        background: ${BG_MAIN};
        
        svg {
            fill: ${FONT_MAIN};
        }
    }
`;

const Content = styled.div`
    padding: 0 2rem 2rem;
    overflow-y: auto;
`;

export const Modal: FC<IModalProps> = ({
                                           container = document.body,
                                           header,
                                           opened,
                                           size = "xl",
                                           shouldCloseOnEsc = true,
                                           shouldCloseOnOverlayClick = false,
                                           onRequestClose,
                                           children
                                       }) => {

    const overlayRef = useRef<HTMLDivElement>(null);

    const handleClose = (e: any) => {
        e.stopPropagation();
        onRequestClose && onRequestClose();
    };

    const handleEscKeyDown = (e: KeyboardEvent) => {
        if (opened && shouldCloseOnEsc && e.keyCode === KEY_CODES.ESCAPE) {
            handleClose(e);
        }
    };

    const handleOverlayClick = (e: MouseEvent) => {
        if (opened && shouldCloseOnOverlayClick && e.target === overlayRef.current) {
            handleClose(e);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscKeyDown);
        return () => {
            document.removeEventListener('keydown', handleEscKeyDown);
        };
    });

    const nodeRef = useRef(null)

    return (
        opened
            ?
            ReactDOM.createPortal(
                <Overlay show={ opened }
                         fixed={true}
                         onClick={ handleOverlayClick }>
                    <Draggable handle=".draggable-handle">
                        <ModalComponent
                            width={ SIZES[size].width }
                            height={ SIZES[size].height }
                        >
                            <Header className="draggable-handle">
                                { header }
                                <IconClose onClick={ handleClose }>
                                    <IconTimes />
                                </IconClose>
                            </Header>
                            <Content>{ children }</Content>
                        </ModalComponent>
                    </Draggable>
                </Overlay>,
                container
            )
            :
            null
    )
}
