import { axios } from "../axios";
import { Tokens } from "./index";

export const oAuthApi = {
    issueTokens: async (authCode: string) => {
        return axios.post<Tokens>(
            `/auth/v1/oauth/token`,
            new URLSearchParams({
                code: authCode,
                grant_type: 'authorization_code',
                client_id: process.env.REACT_APP_CLIENT_ID || '',
                client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
    },

    refreshTokens: async (refreshToken: string) => {
        return axios.post<Tokens>(
            `/auth/v1/oauth/token`,
            new URLSearchParams({
                refresh_token: refreshToken,
                grant_type: 'refresh_token',
                client_id: process.env.REACT_APP_CLIENT_ID || '',
                client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
    },

}
