import {DefaultFilter, DefaultFilterForm, DefaultFilterInstance, TablePage} from "../../../ui-kit/pages/TablePage";
import {UserList, usersApi} from "../../../api/authApi/usersApi";
import {useTranslation} from "react-i18next";
import {CreateUserForm} from "./createUserForm";
import {EditButton} from "../../components/EditButton";
import {useNavigate} from "react-router-dom";
import {DefaultPageWrapper} from "../../components/DefaultPageWrapper";

export const UsersPage = () => {

    const {t} = useTranslation(["users"])
    const navigate = useNavigate();

    return <DefaultPageWrapper>
        <TablePage
            api={usersApi}
            columns={[
                {id: "brand", name: t('users:users.table.brand') as string},
                {id: "email", name: t('users:users.table.email') as string},
                {id: "residenceCountry", name: t('users:users.table.country') as string, render: (el: UserList) => el.residenceCountry.description},
                {id: "name", name: t('users:users.table.name') as string, render: (el: UserList) => [el.name, el.middleName, el.lastName].join(" ")},
                {id: "twoFactorKind", name: t('users:users.table.twoFactorKind') as string},
                {id: "verificationIdentity", name: t('users:users.table.verificationIdentity') as string, render: (el: UserList) => `${el.verification.identity}`},
                {id: "verificationResidence", name: t('users:users.table.verificationResidence') as string, render: (el: UserList) => `${el.verification.residence}`},
                {id: "roles", name: t('users:users.table.roles') as string, render: (el: UserList) => `${el.roles.join(", ")}`},
                {id: "edit", width: 4, render: (el: UserList) => <EditButton onClick={() => navigate(el.id)}/>},
            ]}
            filterForm={DefaultFilterForm}
            initFilter={DefaultFilterInstance}
            createFormTitle={t('users:users.form.createTitle')}
            createForm={CreateUserForm}
            translations={["system", "app"]}
            formSize={"xxxl"}
            pageSize={10}
        />
    </DefaultPageWrapper>
}
