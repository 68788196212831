import {IEditForm} from "../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {CryptoVerification, cryptoVerificationApi} from "../../../api/authApi/cryptoVerificationApi";
import styled from "styled-components";
import {DANGER, FONT_MAIN_ALT} from "../../../ui-kit/style-constants";
import {IconPrint} from "../../../ui-kit/icons/IconPrint";
import {IconButton} from "../../components/IconButton";
import {Button} from "../../../ui-kit/components/Button";
import {number} from "yup";

const Result = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(7, 1fr);
    align-self: start;
`;

const ResultBlock = styled.div`
    display: grid;
    grid-gap: 0.5rem;
`;

const Address = styled(ResultBlock)`
    grid-column: 1 / 4;
`;


const Customer = styled(ResultBlock)`
    grid-column: 4 / 6;
`;

const Time = styled(ResultBlock)`
    grid-column: 6 / 8;
`;

const Print = styled(ResultBlock)`
    grid-column: 7 / 8;
`;

const Transaction = styled(ResultBlock)`
    grid-column: 1 / 6;
`;
const VerifiedAt = styled(ResultBlock)`
    grid-column: 1 / 4;
`;
const VerifiedBy = styled(ResultBlock)`
    grid-column: 4 / 8;
`;

const Label = styled.div`
    font-size: 0.8rem;
    color: ${FONT_MAIN_ALT};
    font-weight: 300;
`;

const Value = styled.div<{danger ?: boolean}>`
    color: ${({danger}) => danger ? DANGER : 'currentColor'};
`;

const Data = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
`;

export const CryptoVerificationViewForm = ({entity, submit}: IEditForm<CryptoVerification>) => {
    const {t} = useTranslation(['customers'])


    const print = (id: string) => {
        return cryptoVerificationApi.print(id)
    }

    return (
        <Data>
            <Result>
                <Address>
                    <Label>Address</Label>
                    <Value>{entity?.result?.address || "No data"}</Value>
                </Address>
                <Customer>
                    <Label>Customer</Label>
                    <Value>{entity?.customer?.name || "None"}</Value>
                </Customer>
                <Print>
                    <Button color="primary" onClick={() => entity?.id && print(entity?.id) } ><IconPrint />&nbsp;&nbsp;Print</Button>
                </Print>
                <Transaction>
                    <Label>Transaction Hash</Label>
                    <Value>{entity?.result?.txn || "No data"}</Value>
                </Transaction>
                <Time>
                    <Label>Time</Label>
                    <Value>{entity?.result?.time ? new Date(entity.result.time).toLocaleString() : "No data"}</Value>
                </Time>

                <VerifiedAt>
                    <Label>Verified At</Label>
                    <Value>{entity?.createdAt ? new Date(entity.createdAt).toLocaleString() : "No data"}</Value>
                </VerifiedAt>
                <VerifiedBy>
                    <Label>Verified By</Label>
                    <Value>{`${entity?.createdBy?.name} ${entity?.createdBy?.lastName}`}</Value>
                </VerifiedBy>


                <ResultBlock>
                    <Label>Crypto Amount</Label>
                    <Value>{((entity?.result?.amount || 0) / 1000000).toLocaleString() || "No data"}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Risk Score</Label>
                    <Value danger={(entity?.result?.riskScore || 0) > 20 }>{entity?.result?.riskScore?.toLocaleString()}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Exchange Licensed</Label>
                    <Value>{entity?.result?.signals?.exchangeLicensed?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Wallet</Label>
                    <Value>{entity?.result?.signals?.wallet?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Marketplace</Label>
                    <Value>{entity?.result?.signals?.marketplace?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Illegal Service</Label>
                    <Value>{entity?.result?.signals?.illegalService?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Exchange Fraudulent</Label>
                    <Value>{entity?.result?.signals?.exchangeFraudulent?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Crypto Asset</Label>
                    <Value>{entity?.token || entity?.currency }</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Risk Level</Label>
                    <Value>{entity?.result?.riskLevel}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Exchange Not Licensed</Label>
                    <Value>{entity?.result?.signals?.exchangeUnlicensed?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>ATM</Label>
                    <Value>{entity?.result?.signals?.atm?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Gambling</Label>
                    <Value>{entity?.result?.signals?.gambling?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Scam</Label>
                    <Value>{entity?.result?.signals?.scam?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Enforcement Action</Label>
                    <Value>{entity?.result?.signals?.enforcementAction?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Fiat Amount</Label>
                    <Value>{((entity?.result?.fiat || 0) / 100).toLocaleString() || "No data"}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Risky Volume</Label>
                    <Value>{((entity?.result?.riskyVolume || 0) / 1000000).toLocaleString()}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>P2P Exchange Licensed</Label>
                    <Value>{entity?.result?.signals?.p2pExchangeLicensed?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Liquidity Pools</Label>
                    <Value>{entity?.result?.signals?.liquidityPools?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Dark Market</Label>
                    <Value>{entity?.result?.signals?.darkMarket?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Stolen Coins</Label>
                    <Value>{entity?.result?.signals?.stolenCoins?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Seized Assents</Label>
                    <Value>{entity?.result?.signals?.seizedAssets?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Fiat Code</Label>
                    <Value>{entity?.result?.fiatCode}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Risky Volume Fiat</Label>
                    <Value>{((entity?.result?.riskyVolumeFiat || 0) / 100).toLocaleString()}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>P2P Exchange Not Licensed</Label>
                    <Value>{entity?.result?.signals?.p2pExchangeUnlicensed?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Mixer</Label>
                    <Value>{entity?.result?.signals?.mixer?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Dark Service</Label>
                    <Value>{entity?.result?.signals?.darkService?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Terrorism Financing</Label>
                    <Value>{entity?.result?.signals?.terrorismFinancing?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Sanctions</Label>
                    <Value>{entity?.result?.signals?.sanctions?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Direction</Label>
                    <Value>{entity?.result?.direction?.toLocaleString()}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Status</Label>
                    <Value>{entity?.result?.status?.toLocaleString()}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Payment Processor</Label>
                    <Value>{entity?.result?.signals?.payment?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Miner</Label>
                    <Value>{entity?.result?.signals?.miner?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Ransom</Label>
                    <Value>{entity?.result?.signals?.ransom?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Child Exploitation</Label>
                    <Value>{entity?.result?.signals?.childExploitation?.toLocaleString() || 0}</Value>
                </ResultBlock>
                <ResultBlock>
                    <Label>Other</Label>
                    <Value>{entity?.result?.signals?.other?.toLocaleString() || 0}</Value>
                </ResultBlock>

            </Result>
        </Data>

    )
}
