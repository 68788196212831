import { axios } from "../axios";
import { ApiPage } from "../types/apiPage";

export interface Device {
    id: string,
    userAgent: string,
    lastIp: string,
    lastAuthorizationAt: Date,
    deviceType: string,
    operatingSystem: string,
    application: string,
    currentDevice: boolean,
    ipDetails: {
        country: string,
        region: string,
        city: string,
        zip: string,
        lat: string,
        lon: string
    }
}

export const devicesApi = {
    list: async (trusted: boolean, pageNumber: number = 0, pageSize: number = 10) => {
        return (await axios.get<ApiPage<Device>>(`/auth/v1/devices`, {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                trusted: trusted
            }
        })).data
    },
    delete: async (id: string) => {
        return (await axios.delete(`/auth/v1/devices/${ id }`))
    }
}
