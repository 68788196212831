import { FC, ReactNode } from "react";
import { Spinner } from "../Spinner";
import styled from "styled-components";
import { BG_MAIN } from "../../style-constants";
import { transparentize } from "polished";
import { MouseEvent } from "react";

interface IOverlayProps {
    fixed?: boolean,
    show: boolean,
    spinner?: boolean,
    transparency?: number,
    onClick?: (e: MouseEvent) => void,
    children?: ReactNode,
}

const OverlayComponent = styled.div<{transparency: number, show: boolean, fixed: boolean}>`
    position: ${({fixed}) => fixed ? 'fixed': 'absolute'};
    left: 0;
    right : 0;
    top: 0;
    bottom: 0;
    display: ${({show}) => show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    background: ${({transparency}) => transparentize(transparency, BG_MAIN)};
`;

export const Overlay: FC<IOverlayProps> = ({
    fixed = false,
    spinner,
    show,
    transparency = 0.3,
    onClick,
    children
}) => {
    return <OverlayComponent onClick={ (e) => onClick && onClick(e) } transparency={transparency} show={show} fixed={fixed}>
        { spinner && <Spinner /> }
        { children }
    </OverlayComponent>
};
