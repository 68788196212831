import {countriesApi, Country} from "../../../../../api/dictionaries/countries";
import {useForm} from "react-hook-form";
import {FormInputText} from "../../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {Form, FormRow} from "../../../../../ui-kit/forms/Form";
import {useTranslation} from "react-i18next";
import {FC} from "react";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import {Checkbox} from "../../../../../ui-kit/inputs";
import {IEditForm} from "../../../../../ui-kit/pages/TablePage";
import {FormCheckbox} from "../../../../../ui-kit/forms/FormInputs/FormCheckbox";

export const CountryForm = ({submit, entity = undefined}: IEditForm<Country>) => {

    const {t} = useTranslation(['system'])

    const form = useForm<Country>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(countriesApi.schemas.common, {}, {})
    })

    return (
        <Form onSubmit={ form.handleSubmit(submit) } overlay={ form.formState.isSubmitting } overlaySpinner={ true }>
            <FormInputText name="id" form={form} hidden={true} />
            <FormRow sizes={["1fr", "6rem"]}>
                <FormInputText name="code"
                               label={t("system:dictionaries.countries.code") as string}
                               form={form}
                />
                <FormCheckbox name={"prohibited"}
                          label={t("system:dictionaries.countries.prohibited") as string}
                          form={form}
                />

            </FormRow>
            <FormRow sizes={["1fr", "1fr"]}>
                <FormInputText name="iso2"
                               label={t("system:dictionaries.countries.iso2") as string}
                               form={form}
                />
                <FormInputText name="iso3"
                               label={t("system:dictionaries.countries.iso3") as string}
                               form={form}
                />
            </FormRow>
            <FormInputText name="name"
                           label={t("system:dictionaries.countries.name") as string}
                           form={form}
            />

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={ {
                translationNamespaces: ['app'],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            } }/>

        </Form>
    )

}
