import {useTranslation} from "react-i18next";
import {DefaultFilterForm, DefaultFilterInstance, TablePage} from "../../../../../ui-kit/pages/TablePage";
import {businessActivitiesApi, BusinessActivity} from "../../../../../api/dictionaries/businessActivities";
import {BusinessActivitiesForm} from "./businessActivitiesForm";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";

export const BusinessActivitiesPage = () => {
    const {t} = useTranslation(["system", "app"])

    return <DefaultPageWrapper>
        <TablePage
            api={businessActivitiesApi}
            columns={[
                {id: "code", name: t('system:dictionaries.businessActivities.code') as string,},
                {id: "name", name: t('system:dictionaries.businessActivities.name') as string},
                {id: "country", name: t('system:dictionaries.businessActivities.country') as string, render: (el: BusinessActivity) => el.country?.description || t('system:dictionaries.businessActivities.global')},

            ]}
            filterForm={DefaultFilterForm}
            initFilter={DefaultFilterInstance}
            editFormTitle={t('system:dictionaries.businessActivities.titleSingle')}
            editForm={BusinessActivitiesForm}
            translations={["system", "app"]}
            pageSize={10}
        />
    </DefaultPageWrapper>
}

