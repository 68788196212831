import React from "react";
import {SingleValue, StylesConfig} from "react-select";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {FONT_EXTRA, FONT_MAIN, FONT_MAIN_DISABLED, INPUT_BG, INPUT_BG_DISABLED, OUTLINE_DANGER, OUTLINE_PRIMARY, PRIMARY, PRIMARY_HOVER} from "../../style-constants";
import {darken, lighten} from "polished";
import {AsyncPaginate, LoadOptions} from "react-select-async-paginate";
import {SelectAsyncProps} from "./SelectAsyncProps";
import {SelectAsyncItem} from "./SelectAsyncItem";
import {selectCustomStyling} from "./SelectCustomStyling";

export const SelectAsync = <Item extends SelectAsyncItem, TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    {
        value,
        loadItems,
        multiple,
        error = false,
        disabled = false,
        onChange,
        onBlur,
        ...props
    }: SelectAsyncProps<Item>) => {

    const loadOptions: LoadOptions<any, any, any> = async (search: string, loadedOptions, {page}) => {
        const result = await loadItems(search, page)
        return {
            options: result.items.map((el: Item) => ({
                value: el.id,
                label: el.description,
                item: el
            })),
            hasMore: result.hasMore,
            additional: {
                page: page + 1,
            },
        }
    }

    return (
        <AsyncPaginate loadOptions={ loadOptions }
                       styles={ selectCustomStyling(error, disabled) }
                       value={ value ? {
                           value: value.id,
                           label: value.description,
                           item: value
                       } : undefined }
                       isDisabled={disabled}
                       onChange={(value) => onChange ? onChange(value ? value.item : undefined) : undefined }
                       onBlur={() => onBlur ? onBlur(value) : undefined}
                       menuPortalTarget={document.body}
                       additional={{
                           page: 0,
                       }}
                       debounceTimeout={200}
                       {...props}
        />


    )
}
