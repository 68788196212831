import * as yup from "yup";
import {axios} from "../axios";
import {ApiPage} from "../types/apiPage";
import {DefaultFilter} from "../../ui-kit/pages/TablePage";
import {ApiReference} from "../types/apiReference";

export interface BusinessActivity {
    id: string,
    code: string,
    name: string,
    country: ApiReference<string> | undefined,
}

export const businessActivitiesApi = {
    BASE_URL: "/dictionaries/v1/business-activities",
    schemas: {
        common: yup.object()
            .shape({
                code: yup.string()
                    .required('required'),
                name: yup.string()
                    .required('required'),
                country: yup.object()
                    .notRequired()
                    .shape({
                        id: yup.string()
                            .required('required'),
                        description: yup.string(),
                    })
            }),
    },

    list: async (filter?: DefaultFilter, page: number = 0, size: number = 25) => {
        return (await axios.get<ApiPage<BusinessActivity>>(businessActivitiesApi.BASE_URL, {
            params: {
                filter: filter?.filter,
                page: page,
                size: size
            }
        })).data
    },

    get: async (id: string) => {
        return (await axios.get<BusinessActivity>(`${businessActivitiesApi.BASE_URL}/${id}`)).data
    },

    post: async (currency: BusinessActivity) => {
        return (await axios.post<BusinessActivity>(businessActivitiesApi.BASE_URL, currency)).data
    },

    put: async (currency: BusinessActivity) => {
        return (await axios.put<BusinessActivity>(`${businessActivitiesApi.BASE_URL}/${currency.id}`, currency)).data
    },

    delete: async (id: string) => {
        return (await axios.delete(`${businessActivitiesApi.BASE_URL}/${id}`))
    },

}
