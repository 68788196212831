import { EventEmitter } from 'events';

import {NotificationProps, CreateReadyNotification, SimpleNotification} from './NotificationProps';
import {ReactNode} from "react";

const CHANGE_EVENT_NAME = 'change';

const generateId = () => `${new Date().getTime()}${Math.random()}`;


class NotificationManagerImpl extends EventEmitter {
    private notifications: NotificationProps[] = [];

    private emitChange() {
        this.emit(CHANGE_EVENT_NAME, this.notifications);
    }

    createLine(notification: SimpleNotification) {
        this.create({
            type: "line",
            ...notification
        })
    }

    createToast(notification: SimpleNotification) {
        this.create({
            type: "toast",
            ...notification
        })
    }

    errorToast(content: ReactNode, title: string = "Error", delay: number = 3000) {
        this.createToast({
            kind: "error",
            content: content,
            title: title,
            delay: delay
        })
    }

    create(notification: CreateReadyNotification) {
        console.log("creating notification", notification)
        this.notifications.push({
            id: generateId(),
            ...notification,
        });
        this.emitChange();
    }

    remove(notification: NotificationProps) {
        this.notifications = this.notifications.filter((n) => notification['id'] !== n.id);
        this.emitChange();
    }

    addEventListener(callback: any) {
        this.addListener(CHANGE_EVENT_NAME, callback);
    }

    removeEventListener(callback: any) {
        this.removeListener(CHANGE_EVENT_NAME, callback);
    }
}

export const NotificationManager = new NotificationManagerImpl()

export const toast = ({delay = 3000, ...props}: SimpleNotification) => {
    NotificationManager.createToast({
        delay: 3000,
        ...props
    });
}

