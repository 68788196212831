import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import {Customer} from "../../../../../api/customer/customersApi";
import {TablePage} from "../../../../../ui-kit/pages/TablePage";
import {dealsApi} from "../../../../../api/customer/contractsApi";
import {CustomerDealEditForm} from "./CustomerDealEditForm";
import {CustomerSubEntityFilterForm} from "../../CusotmerSubentityFilter";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";

export const CustomerDeals = () => {
    const {t} = useTranslation(["customers"])
    const {customer} = useOutletContext<{ customer: Customer }>()

    return (
        <DefaultPageWrapper line={false}>
            {customer && <TablePage api={dealsApi}
                                    filterForm={CustomerSubEntityFilterForm}
                                    initFilter={{customer}}
                                    columns={[
                                        {id: "dealType", name: t("customers:deals.dealType") as string},
                                        {id: "dealNumber", name: t("customers:deals.dealNumber") as string},
                                        {id: "dealDate", name: t("customers:deals.dealDate") as string}
                                    ]}
                                    translations={["customers"]}
                                    pageSize={25}
                                    initCreation={ {customer: customer.id} }
                                    editFormTitle={t('customers:deals.titleSingle')}
                                    editForm={CustomerDealEditForm}
                                    formSize="xxxl"
            />}
        </DefaultPageWrapper>
    )
}
