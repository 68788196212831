import { FC, useEffect, useState } from "react";
import { NotificationProps, NotificationKind, TRANSITION_STYLES, TRANSITION_DURATION } from "./NotificationProps";
import styled from "styled-components";
import { BG_MAIN, BG_MAIN_ALT, DANGER, PRIMARY, PRIMARY_EXTRA } from "../style-constants";
import { mix, transparentize } from "polished";
import { Transition } from "react-transition-group";
import { useGesture } from "../hooks/useGesture";

const COLORS = {
    info: PRIMARY,
    success: PRIMARY,
    warning: PRIMARY_EXTRA,
    error: DANGER
}

const Wrapper = styled.div<{kind: NotificationKind}>`
    width: 18rem;
    margin: 0.5rem;
    background-color: ${({kind}) => mix(0.1, COLORS[kind], BG_MAIN_ALT)};
    border-left: 0.25rem solid ${({kind}) => COLORS[kind]};
    padding: 1rem;
    box-shadow: 0 0 1rem 0.2rem ${transparentize(0.5, BG_MAIN)};
    cursor: pointer;
    pointer-events: auto;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1rem;
`;

const Body = styled.div`
`;

export const ToastNotification: FC<{
    notification: NotificationProps,
    onShow?: () => void,
    onHide?: () => void
}> = ({
    notification,
    onShow,
    onHide
}) => {

    const [gesture, gestureBinding] = useGesture();
    const [visible, setVisible] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        (gesture === 'left' || gesture === 'right') && setVisible(false);
    }, [gesture, setVisible]);

    const applyTimer = () => {
        if (notification.delay) {
            const timer = setTimeout(() => {
                close();
                clearTimeout(timer);
            }, notification.delay);
        }
    };

    const close = () => {
        setVisible(false);
    }

    return (
        <Transition in={isMounted && visible}
                    timeout={TRANSITION_DURATION}
                    onEntered={() => applyTimer()}
                    onEnter={() => onShow && onShow()}
                    onExited={() => onHide && onHide()}>
            {(state) =>
                <Wrapper kind={notification.kind}
                         {...(gestureBinding as object)}
                         style={{
                             transition: `opacity ${TRANSITION_DURATION}ms ease-in-out, transform ${TRANSITION_DURATION}ms ease-in-out`,
                             ...TRANSITION_STYLES[state],
                         }}
                         onClick={ close}>
                    <Title>
                        {notification.title}
                    </Title>
                    <Body>
                        {notification.content}
                    </Body>
                </Wrapper>
            }
        </Transition>
    )
}
