import React, { InputHTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { InputLabel } from "./base/InputLabel";
import { InputWrapper } from "./base/InputWrapper";
import { InputHint } from "./base/InputHint";
import { InputComponent } from "./base/InputComponent";
import { resolveError } from "../forms/FormInputs/utils";
import { IInput } from "./IInput";
import styled from "styled-components";
import { Button } from "../components/Button";

interface BackoffProps {
    backoff: number | undefined,
    backoffLabel: string,
    backoffNs: string[],
    action: () => void
}

const InputComponentWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const BackoffButton = styled(Button)`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem;
    height: initial;
    font-size: 0.8rem;
    
    cursor: pointer;
`;

export const InputBackoff = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>({
    name,
    hint,
    label,
    form,
    error = false,
    disableErrorHint = false,
    backoff,
    backoffLabel,
    backoffNs,
    action,
    ...props
}: PropsWithChildren<IInput<TFieldValues, TContext> & Omit<InputHTMLAttributes<HTMLInputElement>, "name" | "form" | "type">> & BackoffProps) => {

    const {t} = useTranslation(["app", ...backoffNs])

    const errors = form.formState.errors as { [key in keyof TFieldValues]: FieldError }
    const touchedFields = form.formState.touchedFields as { [key in keyof TFieldValues]: boolean }

    const errorHint = touchedFields[name] && resolveError(t, errors[name])

    const disabled = props.disabled || form.formState.isSubmitting

    const [currentBackoff, setCurrentBackoff] = useState<number | undefined>(undefined)

    const inputWrapperRef = useRef<any>()

    const setupPadding = () => {
        if (inputWrapperRef) {
            const inputWrapper = inputWrapperRef.current.firstChild
            const button = inputWrapperRef.current.lastChild
            inputWrapper.style.paddingRight = `${button.offsetWidth + 25}px`
        }
    }

    useEffect(() => {
        setupPadding()
    }, [inputWrapperRef])

    useEffect(() => {
        setCurrentBackoff(backoff)
        setupPadding()
    }, [backoff])

    useEffect(() => {
        if (currentBackoff) {
            window.setTimeout(() => {
                setCurrentBackoff(currentBackoff - 1)
            }, 1000)
        }
        setupPadding()
    }, [currentBackoff])

    const performBackoffAction = () => {
        if (action) action()
    }

    return (
        <InputWrapper>
            {
                !!label && <InputLabel htmlFor={ name }>{ label }</InputLabel>
            }
            <InputComponentWrapper ref={inputWrapperRef}>
                <InputComponent {
                                    ...form.register(
                                        name,
                                        {
                                            onChange: props.onChange,
                                            onBlur: props.onBlur,

                                        }
                                    )
                                }
                                { ...props }
                                type={ 'text' }
                                error={ touchedFields[name] && !!errors[name]?.type }
                                disabled={ disabled }
                                id={ name }
                                name={ name }>
                </InputComponent>
                <BackoffButton type="button"
                               color={"extra"}
                               onClick={ performBackoffAction }
                               size={"xs"}
                               disabled={ disabled || !!currentBackoff}>
                    { !currentBackoff ? t(backoffLabel) : `${currentBackoff} ${t('app:time.short.second')}` }
                </BackoffButton>
            </InputComponentWrapper>

            {
                !disableErrorHint && (hint || errorHint) && <InputHint error={ errorHint }>{ errorHint || hint }</InputHint>
            }

        </InputWrapper>
    )
}
