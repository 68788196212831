import {IEditForm} from "../../../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Form} from "../../../../../ui-kit/forms/Form";
import {FormInputText} from "../../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSelect} from "../../../../../ui-kit/forms/FormInputs/FormSelect";
import {FormSaveButton} from "../../../../../ui-kit/forms/FormSaveButton";
import {CustomerUser, CustomerUserRole} from "../../../../../api/customer/customersApi";
import {getEnumItems} from "../../../../../ui-kit/utils/enumUtils";

export const CustomerUserEditForm = ({entity, submit}: IEditForm<CustomerUser>) => {
    const {t} = useTranslation(['customers'])

    const form = useForm<CustomerUser>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        //resolver: yupResolver(accountsApi.schemas.common, {}, {})
    })

    return (
        <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
            <FormInputText name="id" form={form} hidden={true}/>
            <FormSelect name="role"
                        label={t("customers:users.form.role")}
                        form={form}
                        items={getEnumItems(CustomerUserRole)}
            />

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ['app'],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            }}/>
        </Form>

    )
}
