import styled from "styled-components";
import {FieldValues} from "react-hook-form/dist/types/fields";
import React, {InputHTMLAttributes, PropsWithChildren, ReactNode} from "react";
import {InputLabel} from "./base/InputLabel";
import { IInput } from "./IInput";
import {FONT_MAIN} from "../style-constants";

const CheckboxWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5rem;
    align-items: center;
`;

const CheckboxComponent = styled.input`
    width: 1rem;
    height: 1rem;
`;

export const CheckBoxLabel = styled.label`
    display: inline-block;
    color: ${ FONT_MAIN };
    font-size: 0.9rem;
    font-weight: 300;
`;

export const Checkbox = (
    {
        id = Math.random().toString(),
        label,
        ...props
    }: { label: ReactNode | undefined } & Omit<InputHTMLAttributes<HTMLInputElement>, "type">
) => {
    return (
        <CheckboxWrapper>

            <CheckboxComponent {...props}
                               type="checkbox"
                               disabled={props.disabled}
                               id={id}/>

            {
                !!label && <CheckBoxLabel htmlFor={id}>{label}</CheckBoxLabel>
            }
        </CheckboxWrapper>
    )
}
