import {Outlet, useParams} from "react-router-dom";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {Customer, customersApi} from "../../../../api/customer/customersApi";
import {useTranslation} from "react-i18next";
import {Spinner} from "../../../../ui-kit/components/Spinner";
import {ModuleMenu} from "../../../components/ModuleMenu";
import {IconChevronLeft} from "../../../../ui-kit/icons/IconChevronLeft";
import {ModuleMenuBackButton} from "../../../components/ModuleMenu/ModuleMenuBackButton";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
`;

const CustomerInfoWrapper = styled.div`
    width: 15rem;
`;

const CustomerInfo = styled.div`
    width: 15rem;
    padding: 1rem;
`;

const Logo = styled.div`

`;

const Property = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-gap: 0.5rem;
`;
const Label = styled.div`
    font-weight: 200;
    font-size: 0.8rem;
`;
const Value = styled.div`
`;

const MainArea = styled.div`
`;

const Menu = styled.div`
`;

export const CustomerPage = () => {

    const {t} = useTranslation(['app', 'customers']);
    const {customerId} = useParams()
    const [customer, setCustomer] = useState<Customer | undefined>()

    useEffect(() => {
        if (customerId) {
            customersApi.get(customerId).then(customer => {
                setCustomer(customer)
            })
        } else {
            setCustomer(undefined)
        }
    }, [customerId])

    return (
        <Wrapper>
            <CustomerInfoWrapper>
                {
                        <>
                            <ModuleMenu elements={[{
                                tag: <ModuleMenuBackButton>
                                    <IconChevronLeft />
                                    { t("app:common.back")}
                                </ModuleMenuBackButton>,
                                link: "/customers",
                                neverSelected: true
                            }]} align={"left"}/>
                            {
                                !customer ? <Spinner/> :
                                    <CustomerInfo>
                                        <Logo>Logo</Logo>
                                        <Property>
                                            <Label>{t("customers:customer.name")}</Label>
                                            <Value>{customer?.name}</Value>
                                        </Property>
                                        <Property>
                                            <Label>{t("customers:customer.country")}</Label>
                                            <Value>{customer?.residenceCountry?.description}</Value>
                                        </Property>
                                        <Property>
                                            <Label>{t("customers:customer.registrationNumber")}</Label>
                                            <Value>{customer?.registrationNumber}</Value>
                                        </Property>
                                    </CustomerInfo>
                            }
                        </>
                }
            </CustomerInfoWrapper>
            <MainArea>
                <ModuleMenu elements={[
                    {tag: t("customers:menu.main"), link: "main"},
                    {tag: t("customers:menu.users"), link: "users"},
                    {tag: t("customers:menu.contracts"), link: "contracts"},
                    {tag: t("customers:menu.deals"), link: "deals"},
                    {tag: t("customers:menu.accounts"), link: "accounts"},
                    {tag: t("customers:menu.transactions"), link: "transactions"},
                ]}/>
                <Outlet context={{customer: {...customer}, setCustomer }} />
            </MainArea>
        </Wrapper>
    )

}
