import { sessionDataState } from "../globalState/sessionDataState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentUserState } from "../globalState/currentUserState";
import { useEffect } from "react";
import { authApi } from "../../api/authApi";

export const CurrentUserSetter = () => {
    const sessionData = useRecoilValue(sessionDataState);
    const setCurrentUser = useSetRecoilState(currentUserState);

    useEffect(() => {
        if (!sessionData?.access) return

        authApi.user.get().then(data => {
            setCurrentUser(data)
        })

    }, [setCurrentUser, sessionData?.access])
    return null
}
