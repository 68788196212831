import {ApiReference} from "../types/apiReference";

export enum ChallengeKind {
    Email = 'Email',
    TimeOtp = 'TimeOtp',
    Telegram = 'Telegram'
}

export enum VerificationState {
    None = 'None',
    Started = 'Started',
    Review = 'Review',
    Retry = 'Retry',
    Failed = 'Failed',
    Verified = 'Verified',
}

export enum SystemRole {
    User = 'User',
    ComplianceOfficer = 'ComplianceOfficer',
    ComplianceManager = 'ComplianceManager',
    TechSupport = 'TechSupport',
    TechSupportLead = 'TechSupportLead',
    SystemAdmin = 'SystemAdmin',
    SuperAdmin = 'SuperAdmin'
}


export const ADMIN_ROLES = [
    SystemRole.ComplianceOfficer, SystemRole.ComplianceManager,
    SystemRole.TechSupport, SystemRole.TechSupportLead,
    SystemRole.SuperAdmin, SystemRole.SystemAdmin
]

export interface Verification {
    applicantId?: string,
    identity: VerificationState,
    residence: VerificationState,
    comments: string,
    fullyVerified: boolean
}

export interface CurrentUser {
    id: string;
    name: string;
    lastName: string;
    email: string;
    residenceCountry: ApiReference<string>;
    phone: string;
    timeOtpBounded: boolean;
    twoFactorKind: ChallengeKind;
    photoUrl?: string;
    verification?: Verification;
    roles: SystemRole[]
}
