import styled from "styled-components";
import { PRIMARY } from "../../../ui-kit/style-constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Table } from "../../../ui-kit/components/Table";
import { AuditEvent, auditEventApi } from "../../../api/authApi/auditEventApi";
import { Button } from "../../../ui-kit/components/Button";
import { Form } from "../../../ui-kit/forms/Form";
import { FormInputText } from "../../../ui-kit/forms/FormInputs/FormInputText";
import { FormSaveButton } from "../../../ui-kit/forms/FormSaveButton";
import { useEffect, useState } from "react";
import { ApiPage, EMPTY_API_PAGE } from "../../../api/types/apiPage";
import { Overlay } from "../../../ui-kit/components/Overlay";
import { Spinner } from "../../../ui-kit/components/Spinner";
import { sleep } from "react-hook-form/dist/utils/sleep";

const Wrapper = styled.div`
    border-top: 0.2rem solid ${ PRIMARY };
    padding: 2rem;
    position: relative;
`;

const FilterWrapper = styled.div`
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 1.5rem;
    align-items: end;
`;

interface IFilter {
    entityId: string;
}

export const Audit = () => {

    const PAGE_SIZE = 10;

    const {t} = useTranslation(["audit"])
    const [events, setEvents] = useState<ApiPage<AuditEvent>>(EMPTY_API_PAGE)
    const [overlay, setOverlay] = useState<boolean>(false)

    const filterForm = useForm<IFilter>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange'
    })

    const loadEvents = (filter?: IFilter, number: number = 0, size: number = PAGE_SIZE) => {
        setOverlay(true)
        return auditEventApi.list({
            entityId: filter?.entityId || "",
            pageNumber: number,
            pageSize: size
        })
    }

    const loadMore = (number: number) => {
        loadEvents(filterForm.getValues(), number).then( (data) => {
            setEvents({
                items: [...events.items, ...data.items],
                page: data.page,
                size: data.size,
                hasMore: data.hasMore
            })
            setOverlay(false)
        }, error => {
            console.log(error)
            setOverlay(false)
        })
    }

    const loadNew = (value: IFilter | undefined) => {
        loadEvents(value).then( (data) => {
            setEvents(data)
            setOverlay(false)
        }, error => {
            console.log(error)
            setOverlay(false)
        })
    }

    useEffect(() => {
        loadNew(undefined)
    }, [])

    return (
        <Wrapper>
            <Form onSubmit={ filterForm.handleSubmit(loadNew) } overlay={ filterForm.formState.isSubmitting } overlaySpinner={ true }>
                <FilterWrapper>
                    <FormInputText name="entityId"
                                   label={t("audit:filter.entityId") as string}
                                   form={filterForm}
                    />


                    <FormSaveButton state={filterForm.formState} enabledOnlyIfDirty={true} buttonLabels={ {
                        translationNamespaces: ['cryptoVerification'],
                        save: 'audit:filter.apply',
                        saving: 'audit:filter.applying'
                    } }/>

                </FilterWrapper>
            </Form>

            <Table columns={ [
                {id: "createdAt", name: t('audit:table.createdAt') as string, render: (el: AuditEvent) => new Date(el.createdAt).toLocaleString()},
                {id: "brand", name: t('audit:table.brand') as string},
                {id: "entityType", name: t('audit:table.entityType') as string},
                {id: "entityId", name: t('audit:table.entityId') as string},
                {id: "eventType", name: t('audit:table.eventType') as string},
                {id: "authorId", name: t('audit:table.authorId') as string, render: (el: AuditEvent) => el.authorId ? el.authorId : t('audit:table.system')},
                {id: "description", name: t('audit:table.description') as string},
            ] }
                   items={ events }
                   onLoadMore={ loadMore }
                   overlay={overlay}
            />
        </Wrapper>
    )

}
