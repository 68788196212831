import * as yup from "yup";
import {ILLEGAL_COUNTRIES} from "../../app/constants";
import {axios} from "../axios";
import {CurrentUser} from "../authApi/authBaseTypes";
import {ApiPage} from "../types/apiPage";
import {Session} from "../authApi/sessionsApi";
import {DefaultFilter} from "../../ui-kit/pages/TablePage";

export interface Currency {
    id: string,
    code: string,
    name: string,
    precision: number,
    kind: CurrencyKind
}

export enum CurrencyKind {
    FIAT = 'Fiat',
    CRYPTO = 'Crypto'
}


export const currenciesApi = {
    BASE_URL: "/dictionaries/v1/currencies",
    schemas: {
        common: yup.object({
            id: yup.string(),
            code: yup.string()
                .required('required'),
            name: yup.string()
                .required('required'),
            precision: yup.number()
                .required('required')
                .min(0),
            kind: yup.string()
                .required('required')
        }),
    },

    list: async (filter?: DefaultFilter, page: number = 0, size: number = 25) => {
        return (await axios.get<ApiPage<Currency>>(currenciesApi.BASE_URL, {
            params: {
                filter: filter?.filter,
                page: page,
                size: size
            }
        })).data
    },

    get: async (id: string) => {
        return (await axios.get<Currency>(`${currenciesApi.BASE_URL}/${id}`)).data
    },

    post: async (currency: Currency) => {
        return (await axios.post<Currency>(currenciesApi.BASE_URL, currency)).data
    },

    put: async (currency: Currency) => {
        return (await axios.put<Currency>(`${currenciesApi.BASE_URL}/${currency.id}`, currency)).data
    },

    delete: async (id: string) => {
        return (await axios.delete(`${currenciesApi.BASE_URL}/${id}`))
    },

}
