import React, { useEffect } from "react";
import { withSuspense } from "../utils/hocs/withSuspense";
import { AuthorizeOverlay } from "./components/AuthorizeOverlay";
import { useRecoilState, useRecoilValue } from "recoil";
import { buildSessionData, sessionDataState } from "./globalState/sessionDataState";
import { fingerPrintState } from "./globalState/fingerPrintState";
import { authApi } from "../api/authApi";

const clientId = encodeURIComponent(process.env.REACT_APP_CLIENT_ID || '')
const redirectUri = encodeURIComponent(process.env.REACT_APP_DEFAULT_REDIRECT_URI || '')

const getRedirectUrl = (backUri ?: string) => !backUri ? redirectUri :
    `${redirectUri}${encodeURIComponent(`?back_uri=${backUri}`)}`

const getSsoUrl = (fingerPrint ?: string, backUri ?: string) =>
    `${process.env.REACT_APP_AUTH_API_URL}/v1/oauth/authorize?redirect_uri=${getRedirectUrl(backUri)}&client_id=${clientId}&response_type=code&scope=openid&finger_print=${fingerPrint}`


export const Authorize = withSuspense(() => {

    const fingerPrint = useRecoilValue(fingerPrintState)
    const [sessionData, setSessionData] = useRecoilState(sessionDataState)

    const params = new URLSearchParams(window.location.search);
    const authCode = window.location.pathname === '/authorize' ? params.get('code') : undefined

    useEffect(() => {
        if (!authCode) return

        authApi.oAuth.issueTokens(authCode || '').then(result => {
            setSessionData(buildSessionData(result.data.access_token, result.data.refresh_token))

            const _params = new URLSearchParams(window.location.search);
            if (_params.get("back_uri")) {
                window.location.href = _params.get("back_uri") || ''
            }
        }, error => {
            console.log("Can't get authorization tokens", error)
            //window.location.href = getSsoUrl(fingerPrint?.visitorId)
        })
    }, [authCode, setSessionData])


    useEffect(() => {
        if (!authCode && fingerPrint && !!setSessionData && (!sessionData || sessionData.expiresAt <= new Date())) {

            // No current session - redirecting to sso
            console.log('redirecting to sso')
            window.location.href = getSsoUrl(fingerPrint?.visitorId, window.location.pathname !== '/authorize' ? window.location.href : undefined)
        }
    }, [authCode, fingerPrint, setSessionData, sessionData])

    return <AuthorizeOverlay />

})
