import styled from "styled-components";
import {IFilterForm} from "../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Form} from "../../../ui-kit/forms/Form";
import {FormInputText} from "../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSaveButton} from "../../../ui-kit/forms/FormSaveButton";
import {CustomerSubEntityDefaultFilter} from "../../../api/customer/customerSubEntityFilter";

const FilterFormWrapper = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 1.5rem;
    align-items: end;
`;

export const CustomerSubEntityFilterForm = ({submit, init}: IFilterForm<CustomerSubEntityDefaultFilter>) => {

    const {t} = useTranslation(["customers", "system"])
    const filterForm = useForm<CustomerSubEntityDefaultFilter>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: init
    })

    return <Form onSubmit={filterForm.handleSubmit(submit)} overlay={filterForm.formState.isSubmitting} overlaySpinner={true}>
        <FilterFormWrapper>
            <FormInputText name="customer" form={filterForm} hidden/>
            <FormInputText name="filter"
                           label={t("app:common.filter.title") as string}
                           form={filterForm}
            />


            <FormSaveButton state={filterForm.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ["system"],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            }}/>
        </FilterFormWrapper>
    </Form>

}
