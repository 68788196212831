import {FieldValues} from "react-hook-form/dist/types/fields";
import {IFormInput} from "../IFormInput";
import {SelectProps} from "../../../inputs/Select/SelectProps";
import {useTranslation} from "react-i18next";
import {Controller, FieldError} from "react-hook-form";
import {resolveError} from "../utils";
import {FormInputHint, FormInputLabel, FormInputWrapper} from "../base";
import React from "react";
import {MultiSelect} from "../../../inputs/Select/MultiSelect";

export const FormMultiSelect = <T extends string | number, TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    {
        name,
        hint,
        label,
        form,
        placeholder,
        disabled = false,
        error = false,
        disableErrorHint = false,
        items,
        hidden = false,
        ...props
    }: IFormInput<TFieldValues, TContext> & Omit<SelectProps<T>, "value">) => {

    const {t} = useTranslation(["app"])

    const errors = form.formState.errors as { [key in keyof TFieldValues]: FieldError }
    const touchedFields = form.formState.touchedFields as { [key in keyof TFieldValues]: boolean }

    const errorHint = touchedFields[name] && resolveError(t, errors[name])

    const isError = touchedFields[name] && !!errors[name]?.type
    return (
        <FormInputWrapper hidden={hidden}>
            {
                !!label && <FormInputLabel htmlFor={name}>{label}</FormInputLabel>
            }
            <Controller control={form.control as any}
                        name={name}
                        render={({field}) => {
                            return <MultiSelect items={items}
                                                value={field.value}
                                //@ts-ignore
                                                onChange={val => field.onChange({target: {value: val}})}
                                                onBlur={field.onBlur}
                                                disabled={disabled || form.formState.isSubmitting}
                                                id={name}
                                                name={name}
                                                error={isError}
                                                placeholder={placeholder ? t(placeholder) as string : ''}
                            />
                        }}
            />

            {
                !disableErrorHint && (hint || errorHint) &&
                <FormInputHint error={errorHint}>{errorHint || hint}</FormInputHint>
            }
        </FormInputWrapper>

    )
}
