
const EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export type Validator<T> = (value: T | undefined) => string | undefined;

export const validateEmail: Validator<string> = (email: string | undefined): string | undefined => {
    if (!email) {
        return "sso:Errors.Empty Field"
    }
    if (!EMAIL_REGEXP.test(email)) {
        return "sso:Errors.Bad Email"
    }
    return undefined
}

const alphaLow = new RegExp("[a-z]")
const alphaUp = new RegExp("[A-Z]")
const digits = new RegExp("[0-9]")
const special = new RegExp("[ !\"#\$%&'\(\)\*+,\-./:;<=>?@\[\\]\^_`{|}~]")

export const validatePasswordLength: Validator<string> = (password: string | undefined): string | undefined => {
    if (!password || password.length < 10) {
        return "length"
    }
    return undefined;
}

export const validatePasswordLow: Validator<string> = (password: string | undefined): string | undefined => {
    if (!password || !password.match(alphaLow)) {
        return "lowerCase"
    }
    return undefined;
}

export const validatePasswordUp: Validator<string> = (password: string | undefined): string | undefined => {
    if (!password || !password.match(alphaUp)) {
        return "upperCase"
    }
    return undefined;
}

export const validatePasswordDigits: Validator<string> = (password: string | undefined): string | undefined => {
    if (!password || !password.match(digits)) {
        return "digits"
    }
    return undefined;
}

export const validatePasswordSpecial: Validator<string> = (password: string | undefined): string | undefined => {
    if (!password || !password.match(special)) {
        return "special"
    }
    return undefined;
}

export const validatePassword: Validator<string> = (password: string | undefined): string | undefined => {
    return validatePasswordLength(password) ||
        validatePasswordLow(password) ||
        validatePasswordUp(password) ||
        validatePasswordDigits(password) ||
        validatePasswordSpecial(password)
}

export const validateNotEmpty: Validator<string> = (value: string | undefined): string | undefined => {
    if (!value) {
        return "notEmpty"
    }
    return undefined
}
