import {DefaultFilterForm, DefaultFilterInstance, TablePage} from "../../../../../ui-kit/pages/TablePage";
import {useTranslation} from "react-i18next";
import {CustomerUserEditForm} from "./CustomerUserEditForm";
import {useOutletContext} from "react-router-dom";
import {Customer, CustomerUser, customerUsersApi} from "../../../../../api/customer/customersApi";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";
import {CustomerUserCreateForm} from "./CustomerUserCreateForm";
import {getUserFullName} from "../../../../../api/authApi/usersApi";

export const CustomerUsers = () => {
    const {t} = useTranslation(["customers"])
    const {customer} = useOutletContext<{ customer: Customer }>()

    return (
        <DefaultPageWrapper line={false}>
            {customer?.id &&
                <TablePage api={customerUsersApi.api(customer.id)}
                           filterForm={DefaultFilterForm}
                           initFilter={DefaultFilterInstance}
                           columns={[
                               {id: "email", name: t("customers:users.columns.email")},
                               {id: "name", name: t("customers:users.columns.name"), render: (el: CustomerUser) => getUserFullName(el)},
                               {id: "role", name: t("customers:users.columns.role")}
                           ]}
                           translations={["customers"]}
                           pageSize={25}
                           initCreation={{customer: customer.id}}
                           editFormTitle={t('customers:users.form.titleEdit')}
                           editForm={CustomerUserEditForm}
                           createFormTitle={t('customers:users.form.titleCreate')}
                           createForm={CustomerUserCreateForm}
                           formSize="xl"

                />}
        </DefaultPageWrapper>
    )
}
