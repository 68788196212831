import { axios } from "../axios";
import * as yup from "yup";

export const phoneApi = {
    schemas: {
        getCode: yup.object({
            phone: yup.string()
                .required('required'),
        }),
    },
    getCode: async (data: { phone: string }) => {
        return axios.post<{ backoff: number }>(
            "/auth/v1/phone",
            data
        )
    },
    changePhone: async (data: { phone: string, code: string }) => {
        return axios.post(
            "/auth/v1/phone",
            data
        )
    }
}
