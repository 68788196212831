import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { HEADER_HEIGHT } from "../../constants";
import { PRIMARY, PRIMARY_HOVER, FONT_EXTRA_ALT, FONT_MAIN_ALT } from "../../../ui-kit/style-constants";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../../utils/hocs/withSuspense";

const Menu = styled.div`
    margin-right: 2rem;
    display: flex;
`;

const MenuElement = styled(Link)<{ selected?: boolean }>`
    min-height: ${HEADER_HEIGHT}rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    font-weight: 300;
    cursor: pointer;
    color:  ${ ({selected}) => selected ? FONT_EXTRA_ALT : FONT_MAIN_ALT };

    background: ${ ({selected}) => selected ? PRIMARY : 'none' };
    
    &:hover, &:focus {
        background: ${ PRIMARY_HOVER  };
        color: ${ FONT_EXTRA_ALT };
    }
`;

export const MainMenu = withSuspense(() => {

    const location = useLocation();
    const {t} = useTranslation(['app'])

    return (
        <Menu>
            {/*<MenuElement selected={ location.pathname.startsWith("/otc") }>OTC</MenuElement>*/}
            <MenuElement to="/customers" selected={ location.pathname.startsWith("/customers") }>{t('app:modules.customers')}</MenuElement>
            <MenuElement to="/users" selected={ location.pathname.startsWith("/users") }>{t('app:modules.users')}</MenuElement>
            <MenuElement to="/crypto-verification" selected={ location.pathname.startsWith("/crypto-verification") }>{t('app:modules.crypto-verification')}</MenuElement>
            <MenuElement to="/audit" selected={ location.pathname.startsWith("/audit") }>{t('app:modules.audit')}</MenuElement>
            <MenuElement to="/system" selected={ location.pathname.startsWith("/system") }>{t('app:modules.system')}</MenuElement>
        </Menu>
    )

}, null)
