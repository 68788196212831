import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Form} from "../../../ui-kit/forms/Form";
import {FormInputText} from "../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSelect, FormSelectAsync} from "../../../ui-kit/forms/FormInputs/FormSelect";
import {countriesApi} from "../../../api/dictionaries/countries";
import {FormSaveButton} from "../../../ui-kit/forms/FormSaveButton";
import {CustomerCreateRq, customersApi} from "../../../api/customer/customersApi";
import {yupResolver} from "@hookform/resolvers/yup";
import {BRANDS_ITEMS} from "../../../api/types/brands";

export const CustomerCreateForm: FC<{ submit: (_: CustomerCreateRq) => void, entity?: CustomerCreateRq }> = (
    {
        submit,
        entity = undefined
    }
) => {

    const {t} = useTranslation(['customers'])

    const form = useForm<CustomerCreateRq>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: entity,
        //@ts-ignore
        resolver: yupResolver(customersApi.schemas.create, {}, {})
    })

    return (

        <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
            <FormSelect name="brand"
                        label={t('customers:customer.form.brand')}
                        form={form}
                        items={BRANDS_ITEMS} />
            <FormInputText name="name"
                           label={t('customers:customer.form.name')}
                           form={form}
            />
            <FormInputText name="registrationNumber"
                           label={t('customers:customer.form.registrationNumber')}
                           form={form}
            />
            <FormSelectAsync name="residenceCountry"
                             label={t('customers:customer.form.residenceCountry')}
                             form={form}
                             loadItems={countriesApi.loadCountries(10)}
            />

            <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ['app'],
                save: 'app:common.forms.save',
                saving: 'app:common.forms.saving'
            }}/>

        </Form>
    )

}
