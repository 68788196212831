import styled from "styled-components";
import { FONT_MAIN } from "../../../style-constants";

export const FormInputLabel = styled.label`
    display: inline-block;
    color: ${ FONT_MAIN };
    margin-bottom: 0.5rem;
    font-weight: 300;
    font-size: 0.85rem;
`;
