import {axios} from "../axios";
import * as yup from "yup";
import {ApiPage} from "../types/apiPage";
import {ApiReference} from "../types/apiReference";
import {DefaultFilter} from "../../ui-kit/pages/TablePage";

export interface CustomerList {
    id: string,
    brand: string,
    residenceCountry: ApiReference<string>,
    registrationNumber: string,
    name: string,
    verification: CustomerVerification,
}

export interface Customer {
    id: string,
    brand: string,
    createdAt: string,
    updatedAt: string,
    residenceCountry: ApiReference<string>
    registrationNumber: string,
    name: string,
    verification: CustomerVerification,
    users: CustomerUser[]
}

export interface CustomerVerification {
    applicantId?: string,
    status: CustomerVerificationStatus,
    fullyVerified: boolean,
    comments: string
}

export enum CustomerVerificationStatus {
    None = 'None',
    Started = 'Started',
    Review = 'Review',
    Retry = 'Retry',
    Failed = 'Failed',
    Verified = 'Verified',
}

export interface CustomerUser {
    id: string,
    email: string,
    name: string,
    middleName: string,
    lastName: string,
    role: CustomerUserRole
}

export enum CustomerUserRole {
    Admin = 'Admin',
    Signer = 'Signer',
    User = 'User',
    Auditor = 'Auditor',
}

export interface CustomerCreateRq {
    brand: string,
    name: string,
    registrationNumber: string,
    residenceCountry: string
}

export interface CustomerVerificationToken {
    token: string;
}

export interface CustomerAddUserRequest {
    user: ApiReference<string>,
    role: CustomerUserRole,
}

export interface CustomerEditUserRequest {
    role: CustomerUserRole
}

export const customersApi = {
    BASE_URL: "/auth/customers/admin/v1/customers",
    schemas: {
        create: yup.object({
            brand: yup.string()
                .required(),
            name: yup.string()
                .required('required'),
            registrationNumber: yup.string()
                .required('required'),
            residenceCountry: yup.object()
                .shape({
                    id: yup.string()
                        .required('required'),
                    description: yup.string(),
                    prohibited: yup.boolean()
                })
                .required('required')
                .test('illegal-country', 'failed', (value, {path, createError}) => {
                    return value?.prohibited ? createError({path, message: 'countryProhibited'}) : true
                })
        }),
        /*common: yup.object({
            name: yup.string()
                .required('required'),
            middleName: yup.string(),
            lastName: yup.string(),
            residenceCountry: yup.string()
                .required('required')
                .test('illegal-country', 'failed', (value, {path, createError}) => {
                    return value && ILLEGAL_COUNTRIES.includes(value) ? createError({path, message: 'countryProhibited'}) : true
                }),
        }),*/
    },

    loadCustomers: (pageSize: number) => async (filter: string, page: number) => {
        const result = await customersApi.list({filter: filter}, page, pageSize)
        return {
            items: result.items.map(el => ({
                id: el.id,
                description: `${el.name}`,
            })),
            hasMore: result.hasMore
        }
    },

    list: async (filter?: DefaultFilter, page: number = 0, size: number = 25) => {
        return (await axios.get<ApiPage<CustomerList>>(
            customersApi.BASE_URL, {
                params: {
                    filter: filter?.filter,
                    page: page,
                    size: size
                }
            })).data
    },

    get: async (id: string) => (await axios.get<Customer>(`${customersApi.BASE_URL}/${id}`)).data,

    post: async (rq: CustomerCreateRq) => {
        return (await axios.post<Customer>(customersApi.BASE_URL, rq)).data
    },

    put: async (data: Customer) => {
        return (await axios.put<Customer>(`${customersApi.BASE_URL}/${data.id}`, data)).data
    },

    /*
     delete: async (id: string) => {
         return (await axios.delete(`/auth/v1/customers/${id}`))
     },
    */
}

export const customerUsersApi = {
    schemas: {
        create: yup.object({
            user: yup.object().required('required')
                .shape({
                    id: yup.string().required('required'),
                    description: yup.string()
                }),
            role: yup.string().required('required')
        }),
    },
    api: (customerId: string) => {
        const BASE_URL = `/auth/customers/admin/v1/customers/${customerId}/users`
        return {

            list: async (filter: DefaultFilter, page: number = 0, size: number = 25) => {
                return (await axios.get<ApiPage<CustomerUser>>(
                    BASE_URL, {
                        params: {
                            filter: filter.filter,
                            page: page,
                            size: size
                        }
                    })).data
            },
            get: async (id: string) => (await axios.get<CustomerUser>(`${BASE_URL}/${id}`)).data,
            post: async (rq: CustomerAddUserRequest) => (await axios.post<CustomerUser>(BASE_URL, {
                userId: rq.user?.id,
                role: rq.role,
            })).data,
            put: async (rq: CustomerUser) => (await axios.put<CustomerUser>(`${BASE_URL}/${rq.id}`, rq)).data,
            delete: async (id: string) => await axios.delete(`${BASE_URL}/${id}`)
        }
    }
}


