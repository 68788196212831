import { axios } from "../axios";
import { ChallengeKind } from "./authBaseTypes";

export interface ChangeTwoFactor {
    twoFactorKind: ChallengeKind,
    password: string,
    oldKindCode: string,
    newKindCode: string,
}

export const twoFactorApi = {
    initiate: async (data: { twoFactorKind: ChallengeKind }) => {
        return axios.post(
            "/auth/v1/two-factor",
            data
        )
    },
    change: async (data: ChangeTwoFactor) => {
        return axios.post(
            "/auth/v1/two-factor",
            data
        )
    }
}
