import styled from "styled-components";

export const ModuleMenuBackButton = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
    align-items: baseline;
    svg {
        height: 0.6rem;
        margin-right: 0.5rem;
    }
`;
