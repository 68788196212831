import {FieldValues} from "react-hook-form/dist/types/fields";
import ReactSelect, {StylesConfig} from "react-select";
import {FONT_EXTRA, FONT_MAIN, FONT_MAIN_DISABLED, INPUT_BG, INPUT_BG_DISABLED, OUTLINE_DANGER, OUTLINE_PRIMARY, PRIMARY, PRIMARY_HOVER} from "../../style-constants";
import {darken, lighten} from "polished";
import {SelectProps} from "./SelectProps";
import {SelectItem} from "./SelectItem";
import {selectCustomStyling} from "./SelectCustomStyling";

export const Select = <T extends string | number, TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    {
        value,
        items,
        error = false,
        disabled = false,
        onChange,
        onBlur,
        ...props
    }: SelectProps<T>) => {

    return (
        <ReactSelect options={ items }
                     styles={ selectCustomStyling(error, disabled) }
                     value={ items.find(it => it.value === value) }
                     isDisabled={ disabled }
                     onChange={ (value) => onChange ? onChange((value as SelectItem<T>).value) : undefined }
                     onBlur={ () => onBlur ? onBlur(value) : undefined}
                     menuPortalTarget={ document.body }
                     isMulti={false}
                     { ...props }
        />


    )
}
