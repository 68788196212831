import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../../utils/hocs/withSuspense";
import { BG_MAIN, FONT_EXTRA_DISABLED } from "../../../ui-kit/style-constants";

const FooterWrapper = styled.div`

    background: ${ BG_MAIN };

    padding: 0.5rem 2rem;
    display: flex;
    justify-content: center;

    font-size: 0.8rem;
    color: ${ FONT_EXTRA_DISABLED };

`;

export const Footer = withSuspense(() => {

    const {t} = useTranslation('app')

    return (
        <FooterWrapper>
            <div>&copy; Legex Europe OÜ. {t('app:common.allRightsReserved')}</div>
        </FooterWrapper>
    )
}, null)
