import { useEffect } from "react";
import { axios, getAxiosErrorHandler } from "../../api/axios";
import { sessionDataState } from "../globalState/sessionDataState";
import { useRecoilState } from "recoil";

export const AxiosBounder = () => {
    const [sessionData, setSessionData] = useRecoilState(sessionDataState);

    useEffect(() => {
        const handler = getAxiosErrorHandler([sessionData, setSessionData]);

        //Add axios interceptor and return it's id to be allowed to clean up it later
        const id = axios.interceptors.response.use(undefined, handler);

        return () => {
            //disable interceptor at auth state change
            axios.interceptors.response.eject(id);
        };
    }, [sessionData, setSessionData]);

    return null
}
