import { atom } from "recoil";
import { authApi } from "../../api/authApi";
import { REFRESH_TOKEN_LAG, SESSION_STORAGE_TOKEN_ACCESS, SESSION_STORAGE_TOKEN_REFRESH } from "../constants";

export interface ISessionData {
    sessionId: string;
    access: string;
    refresh: string;
    expiresAt: Date;
}

export const buildSessionData = (access: string, refresh: string) => {
    const token = JSON.parse(atob(access.split(".")[1]))
    return {
        sessionId: token['session'] as string,
        access: access,
        refresh: refresh,
        expiresAt: new Date(token['exp'] * 1000)

    }
}

const loadSessionDataFromStorage: () => ISessionData | undefined = () => {
    const ac = sessionStorage.getItem(SESSION_STORAGE_TOKEN_ACCESS)
    const rf = sessionStorage.getItem(SESSION_STORAGE_TOKEN_REFRESH)

    if (ac && rf) {
        return buildSessionData(ac, rf)
    }
}

const saveSessionData = (data: ISessionData | undefined) => {
    if (data) {
        sessionStorage.setItem(SESSION_STORAGE_TOKEN_ACCESS, data.access)
        sessionStorage.setItem(SESSION_STORAGE_TOKEN_REFRESH, data.refresh)
    } else {
        sessionStorage.removeItem(SESSION_STORAGE_TOKEN_ACCESS)
        sessionStorage.removeItem(SESSION_STORAGE_TOKEN_REFRESH)
    }
}


let refreshTimeout: number | undefined = undefined;

const setupTokenAutoRefresh = (data: ISessionData | undefined, setSessionData: (data: ISessionData | undefined) => void) => {

    if (refreshTimeout) window.clearTimeout(refreshTimeout)

    if (!data) return

    refreshTimeout = window.setTimeout(() => {
        authApi.oAuth.refreshTokens(data?.refresh || '').then(response => {
            const newData = buildSessionData(response.data.access_token, response.data.refresh_token)

            saveSessionData(newData)
            setSessionData(newData)
            setupTokenAutoRefresh(newData, setSessionData)

        }, error => {
            sessionStorage.removeItem(SESSION_STORAGE_TOKEN_ACCESS)
            sessionStorage.removeItem(SESSION_STORAGE_TOKEN_REFRESH)
        })

    }, data.expiresAt.valueOf() - new Date().valueOf() - REFRESH_TOKEN_LAG)

}

export type SessionDataHook = [ISessionData | undefined, (data: ISessionData | undefined) => void];

export const sessionDataState = atom<ISessionData | undefined>({
    key: 'sessionData',
    default: loadSessionDataFromStorage(),
    effects_UNSTABLE: [
        ({ onSet, setSelf }) => {
            onSet(data => {
                saveSessionData(data)
                setupTokenAutoRefresh(data, setSelf)
            })
        },
        ({getPromise, setSelf}) => {
            getPromise(sessionDataState).then(data => setupTokenAutoRefresh(data, setSelf))
        }
    ]
});

