import styled, { css } from "styled-components";
import { DANGER, PRIMARY, FONT_MAIN_DISABLED, INPUT_BG, INPUT_BG_DISABLED, OUTLINE_DANGER, OUTLINE_PRIMARY, BG_EXTRA } from "../../style-constants";

export const InputComponent = styled.input<{ error: boolean }>`
    ${ ({error}) => css`
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 0.25rem;
        border: 1px solid ${ error ? DANGER : BG_EXTRA };
        outline: none;
        box-shadow: ${ error ? OUTLINE_DANGER : '' };
        background-color: ${ INPUT_BG };
        line-height: 1rem;
        height: 2.5rem;

        &:focus {
            border-color: ${ error ? DANGER : PRIMARY };
            box-shadow: ${ error ? OUTLINE_DANGER : OUTLINE_PRIMARY };
        }

        &[disabled] {
            color: ${ FONT_MAIN_DISABLED };
            background-color: ${ INPUT_BG_DISABLED };
        }
    ` }
`;
