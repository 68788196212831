import {useTranslation} from "react-i18next";
import {CurrencyForm} from "./currencyForm";
import {DefaultFilterForm, DefaultFilterInstance, TablePage} from "../../../../../ui-kit/pages/TablePage";
import {currenciesApi, Currency, CurrencyKind} from "../../../../../api/dictionaries/currencies";
import {DefaultPageWrapper} from "../../../../components/DefaultPageWrapper";

export const CurrenciesPage = () => {
    const {t} = useTranslation(["system", "app"])

    return <DefaultPageWrapper>
        <TablePage
            api={currenciesApi}
            columns={[
                {id: "code", name: t('system:dictionaries.currencies.code') as string,},
                {id: "name", name: t('system:dictionaries.currencies.name') as string},
                {id: "precision", name: t('system:dictionaries.currencies.precision') as string},
                {id: "kind", name: t('system:dictionaries.currencies.kind') as string, render: (el: Currency) =>
                    el.kind === CurrencyKind.CRYPTO ? t("system:dictionaries.currencies.crypto")
                        : el.kind === CurrencyKind.FIAT ? t("system:dictionaries.currencies.fiat")
                        : ""
                },
            ]}
            filterForm={DefaultFilterForm}
            initFilter={DefaultFilterInstance}
            editFormTitle={t('system:dictionaries.currencies.titleSingle')}
            editForm={CurrencyForm}
            translations={["system", "app"]}
            pageSize={10}
        />
    </DefaultPageWrapper>
}

