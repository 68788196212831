import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {DefaultPageWrapper} from "../../../components/DefaultPageWrapper";
import {useForm} from "react-hook-form";
import {FullUser, usersApi} from "../../../../api/authApi/usersApi";
import {useEffect, useState} from "react";
import {Form, FormGroup, FormRow} from "../../../../ui-kit/forms/Form";
import {FormInputText} from "../../../../ui-kit/forms/FormInputs/FormInputText";
import {FormCheckbox} from "../../../../ui-kit/forms/FormInputs/FormCheckbox";
import {FormInputDate} from "../../../../ui-kit/forms/FormInputs/FormInputDate";
import {FormSelect, FormSelectAsync} from "../../../../ui-kit/forms/FormInputs/FormSelect";
import {countriesApi} from "../../../../api/dictionaries/countries";
import {FormMultiSelect} from "../../../../ui-kit/forms/FormInputs/FormSelect/FormMultiSelect";
import {getEnumItems} from "../../../../ui-kit/utils/enumUtils";
import {SystemRole, VerificationState} from "../../../../api/authApi/authBaseTypes";
import {FormSaveButton} from "../../../../ui-kit/forms/FormSaveButton";
import styled from "styled-components";
import {NotificationManager} from "../../../../ui-kit/notifications/NotificationManager";
import {IconChevronLeft} from "../../../../ui-kit/icons/IconChevronLeft";
import {ModuleMenu} from "../../../components/ModuleMenu";
import {ModuleMenuBackButton} from "../../../components/ModuleMenu/ModuleMenuBackButton";

const H2 = styled.h2`
    margin-bottom: 2rem;
`;

const Line = styled.div<{ justify: "space-between" | "center" | "start" | "end" | "stretch" }>`
    display: flex;
    justify-content: ${({justify}) => justify};
    ${({justify}) => justify == "stretch" ? `
        >* {
            flex: 1;
        }
    ` : ''};
`;

export const UserPage = () => {

    const {t} = useTranslation(['app', 'users'])
    const {userId} = useParams()

    const [user, setUser] = useState<FullUser | undefined>()

    const form = useForm<FullUser>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: user,
        //@ts-ignore
        //resolver: yupResolver(usersApi.schemas.common, {}, {})
    })

    useEffect(() => {
        if (userId) {
            usersApi.get(userId).then(user => {
                setUser(user)
            })
        } else {
            setUser(undefined)
        }
    }, [userId])

    const submit = (value: FullUser) => {
        return usersApi.put(value).then((data) => {
            setUser(data)
        }, error => {
            console.log("Error", error)
            if (error.response && error.response.data) {
                error.response.data.messages.forEach((m: string) => {
                    NotificationManager.errorToast(m)
                })

            } else {
                console.log("Error during request", error)
            }
        })
    }

    return (<>
            <ModuleMenu elements={[{
                tag: <ModuleMenuBackButton>
                    <IconChevronLeft/>
                    {t("app:common.back")}
                </ModuleMenuBackButton>,
                link: "/users",
                neverSelected: true
            }]} align={"left"}/>

            <DefaultPageWrapper>

                <Form onSubmit={form.handleSubmit(submit)} overlay={form.formState.isSubmitting} overlaySpinner={true}>
                    <Line justify={"space-between"}>
                        <H2>{t("users:users.form.editTitle")}</H2>
                        <FormSaveButton state={form.formState} enabledOnlyIfDirty={true} buttonLabels={{
                            translationNamespaces: ['app'],
                            save: 'app:common.forms.save',
                            saving: 'app:common.forms.saving'
                        }}/>
                    </Line>
                    <Line justify={"stretch"}>
                        <FormGroup title={t('users:users.form.mainInfo')}>
                            <FormInputText name="id" form={form} hidden={true}/>
                            <FormRow sizes={["1fr", "7rem"]}>
                                <FormInputText name="email"
                                               label={t('users:users.form.email')}
                                               disabled={true}
                                               form={form}
                                />
                                <FormCheckbox name="locked"
                                              label={t('users:users.form.locked')}
                                              form={form}
                                />
                            </FormRow>
                            <FormRow sizes={["1fr", "1fr", "1fr"]}>
                                <FormInputText name="name"
                                               label={t('users:users.form.name')}
                                               form={form}
                                />
                                <FormInputText name="middleName"
                                               label={t('users:users.form.middleName')}
                                               form={form}
                                />
                                <FormInputText name="lastName"
                                               label={t('users:users.form.lastName')}
                                               form={form}
                                />
                            </FormRow>
                            <FormRow sizes={["1fr", "1fr"]}>

                                <FormInputDate name="birthDate"
                                               label={t('users:users.form.birthDate')}
                                               form={form}
                                />
                                <FormSelectAsync name="residenceCountry"
                                                 label={t('users:users.form.residenceCountry')}
                                                 form={form}
                                                 loadItems={countriesApi.loadCountries(10)}
                                />
                            </FormRow>

                            <FormMultiSelect name="roles"
                                             form={form}
                                             label={t('users:users.form.roles')}
                                             multiple={true}
                                             items={getEnumItems(SystemRole)}
                            />
                        </FormGroup>

                        <FormGroup title={t('users:users.form.verification')}>
                            <FormInputText name="verification.applicantId"
                                           label={t('users:users.form.applicantId')}
                                           form={form}
                            />
                            <FormSelect name="verification.identity"
                                        label={t('users:users.form.identity')}
                                        form={form}
                                        items={getEnumItems(VerificationState)}/>
                            <FormSelect name="verification.residence"
                                        label={t('users:users.form.residence')}
                                        form={form}
                                        items={getEnumItems(VerificationState)}/>
                            <FormInputText name="verification.comments"
                                           label={t('users:users.form.comments')}
                                           form={form}/>
                        </FormGroup>

                    </Line>

                </Form>


            </DefaultPageWrapper>
        </>
    )

}
