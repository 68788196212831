import styled from "styled-components";
import {PRIMARY} from "../../../ui-kit/style-constants";
import {DefaultFilterInstance, IFilterForm, TablePage} from "../../../ui-kit/pages/TablePage";
import {
    CryptoVerification,
    cryptoVerificationApi,
    CryptoVerificationFilter
} from "../../../api/authApi/cryptoVerificationApi";
import {useTranslation} from "react-i18next";
import {CryptoVerificationViewForm} from "./CryptoVerificationViewForm";
import {CryptoVerificationCreateForm} from "./CryptoVerificationCreateForm";
import {useForm} from "react-hook-form";
import {Form} from "../../../ui-kit/forms/Form";
import {FormInputText} from "../../../ui-kit/forms/FormInputs/FormInputText";
import {FormSaveButton} from "../../../ui-kit/forms/FormSaveButton";
import {IconButton} from "../../components/IconButton";
import {IconPrint} from "../../../ui-kit/icons/IconPrint";
import {FormInputDate} from "../../../ui-kit/forms/FormInputs/FormInputDate";
import {customersApi} from "../../../api/customer/customersApi";
import {FormSelectAsync} from "../../../ui-kit/forms/FormInputs/FormSelect";


const Wrapper = styled.div`

    border-top: 0.2rem solid ${PRIMARY};
    padding: 2rem;

`;

const FilterFormWrapper = styled.div`
    display: grid;
    grid-template-columns: 15rem auto 9rem 9rem 9rem 9rem min-content;
    grid-gap: 1.5rem;
    align-items: end;
`;

export const CryptoVerificationFilterForm = ({submit, init}: IFilterForm<CryptoVerificationFilter>) => {

    const {t} = useTranslation(["customers", "system"])
    const filterForm = useForm<CryptoVerificationFilter>({
        mode: 'all',
        criteriaMode: 'all',
        shouldUnregister: true,
        reValidateMode: 'onChange',
        values: init
    })

    const submitForm = (form: CryptoVerificationFilter) => {
        const timeFrom = form.timeFrom ? new Date(form.timeFrom) : undefined
        if (!!timeFrom) timeFrom.setHours(0, 0, 0, 0)
        const timeTo = form.timeTo ? new Date(form.timeTo) : undefined
        if (!!timeTo) timeTo.setHours(23, 59, 59, 999)
        const verifiedAtFrom = form.verifiedAtFrom ? new Date(form.verifiedAtFrom) : undefined
        if (!!verifiedAtFrom) verifiedAtFrom.setHours(0, 0, 0, 0)
        const verifiedAtTo = form.verifiedAtTo ? new Date(form.verifiedAtTo) : undefined
        if (!!verifiedAtTo) verifiedAtTo.setHours(23, 59, 59, 999)
        submit({
            customer: form.customer,
            filter: form.filter,
            timeFrom: timeFrom?.toISOString()?.slice(0, -1),
            timeTo: timeTo?.toISOString()?.slice(0, -1),
            verifiedAtFrom: verifiedAtFrom?.toISOString()?.slice(0, -1),
            verifiedAtTo: verifiedAtTo?.toISOString()?.slice(0, -1),
        })
    }

    return <Form onSubmit={filterForm.handleSubmit(submitForm)} overlay={filterForm.formState.isSubmitting}
                 overlaySpinner={true}>
        <FilterFormWrapper>
            <FormSelectAsync name="customer"
                             label={t('cryptoVerification:form.customer')}
                             form={filterForm}
                             loadItems={customersApi.loadCustomers(10)} />
            <FormInputText name="filter"
                           label={t("cryptoVerification:filter.allFilterTitle") as string}
                           form={filterForm}
            />
            <FormInputDate name="timeFrom"
                           label={t("cryptoVerification:filter.timeFrom") as string}
                           form={filterForm}
            />
            <FormInputDate name="timeTo"
                           label={t("cryptoVerification:filter.timeTo") as string}
                           form={filterForm}
            />
            <FormInputDate name="verifiedAtFrom"
                           label={t("cryptoVerification:filter.verifiedAtFrom") as string}
                           form={filterForm}
            />
            <FormInputDate name="verifiedAtTo"
                           label={t("cryptoVerification:filter.verifiedAtTo") as string}
                           form={filterForm}
            />


            <FormSaveButton state={filterForm.formState} enabledOnlyIfDirty={true} buttonLabels={{
                translationNamespaces: ["app"],
                save: 'app:common.filter.apply',
                saving: 'app:common.filter.applying'
            }}/>
        </FilterFormWrapper>
    </Form>

}

const print = (id: string) => {
    return cryptoVerificationApi.print(id)
}

export const CryptoVerificationPage = () => {

    const {t} = useTranslation(["cryptoVerification"])
    const ADDRESS_SIZE = 35
    const TRANSACTION_SIZE = 25

    return <Wrapper>
        <TablePage api={cryptoVerificationApi}
                   filterForm={CryptoVerificationFilterForm}
                   initFilter={DefaultFilterInstance}
                   columns={[
                       {id: "time", name: t("cryptoVerification:form.time"), render: (it: CryptoVerification) => it.result.time ? new Date(it.result.time).toLocaleString() : "-"},
                       {id: "direction", name: t("cryptoVerification:form.direction.name")},
                       {id: "customer", name: t("cryptoVerification:form.customer"), render: (it: CryptoVerification) => it.customer?.name || ''},
                       {id: "currency", name: t("cryptoVerification:form.currencyShort")},
                       {id: "token", name: t("cryptoVerification:form.token")},
                       {id: "riskScore", name: t("cryptoVerification:form.risk"), render: (it: CryptoVerification) => it.result?.riskScore},
                       {id: "addressHash", name: t("cryptoVerification:form.address"), width: 20, render: (it: CryptoVerification) => <code>{it.addressHash.length > ADDRESS_SIZE ? `${it.addressHash.slice(0, ADDRESS_SIZE)}...` : it.addressHash}</code>},
                       {id: "transactionHash", name: t("cryptoVerification:form.transaction"), render: (it: CryptoVerification) => <code>{it.transactionHash.length > TRANSACTION_SIZE ? `${it.transactionHash.slice(0, TRANSACTION_SIZE)}...` : it.addressHash}</code>},
                       {id: "createdAt", name: t("cryptoVerification:form.verifiedAt"), render: (it: CryptoVerification) => new Date(it.createdAt).toLocaleString()},
                       {id: "createdBy", name: t("cryptoVerification:form.verifiedBy"), render: (it: CryptoVerification) => `${it.createdBy?.name} ${it.createdBy?.lastName}`},
                       {id: "print", width: 4, render: (it: CryptoVerification) => <IconButton onClick={() => print(it.id)}><IconPrint/></IconButton>},
                   ]}
                   translations={["customers"]}
                   pageSize={25}
                   editFormTitle={t('cryptoVerification:form.title')}
                   editForm={CryptoVerificationViewForm}
                   createFormTitle={t('cryptoVerification:form.title')}
                   createForm={CryptoVerificationCreateForm}
                   formSize="xxxl"

        />
    </Wrapper>
}
