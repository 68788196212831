import { userApi } from "./userApi";
import { timeOtpApi } from "./timeOtpApi";
import { twoFactorApi } from "./twoFactorApi";
import { passwordApi } from "./passwordApi";
import { phoneApi } from "./phoneApi";
import { devicesApi } from "./devices";
import { sessionsApi } from "./sessionsApi";
import { oAuthApi } from "./oauthApi";
import { verificationApi } from "./verificationApi";

export interface Tokens {
    access_token: string,
    refresh_token: string,
    token_type: string
    expires_in: number,
    scope: string
}

export const authApi = {

    oAuth: oAuthApi,
    user: userApi,
    timeOtp: timeOtpApi,
    twoFactor: twoFactorApi,
    password: passwordApi,
    phone: phoneApi,
    devices: devicesApi,
    sessions: sessionsApi,
    verification: verificationApi
}
